import { customerMessagesCollection, groomerMessagesCollection, messagesCollectionGroup, getNonEmptyData, getParentId } from "@marathon/client-side/database";
import { MessageData, MessageParentType, MessageSender, MessageType, MessageTypeFilter, MessageInputData, MessageContactType, MessageTagPetData, Message } from "@marathon/common/entities/Message";
import { DocumentSnapshot, onSnapshot, query, orderBy, limit, doc, addDoc, deleteDoc, where, UpdateData, updateDoc, deleteField, getDocs, startAfter } from "firebase/firestore";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import LocalDate from "@marathon/common/LocalDate";
import { activityOutcomeOptions } from "@marathon/common/constants";
import { formatNames } from "@marathon/common/textHelper";
import { shouldBeUnreachable } from "@marathon/common/typesHelper";
import { Appointment } from "@marathon/common/entities/Appointment";
import { Pet } from "@marathon/common/entities/Pet";

const getMessagesCollection = (collectionType: MessageParentType, parentId: string) => {
    switch (collectionType) {
        case MessageParentType.Customers:
            return customerMessagesCollection(parentId);
        case MessageParentType.Groomers:
            return groomerMessagesCollection(parentId);
        default:
            shouldBeUnreachable(collectionType);
    }
};

const getParentType = (snapshot: DocumentSnapshot<MessageData>): MessageParentType => {
    const collection = snapshot.ref.path.split("/")[0];

    if (collection === MessageParentType.Customers
        || collection === MessageParentType.Groomers)
        return collection;

    throw new Error(`parentType not valid: ${collection}`);
};

const mapEntity = function (snapshot: DocumentSnapshot<MessageData>) {
    return new Message(snapshot.id,
        getParentType(snapshot),
        getParentId(snapshot),
        getNonEmptyData(snapshot));
};

export class MessageRepository {
    static async createSms(input: MessageInputData) {
        if (!input.phone)
            throw new Error("Phone is required for SMS messages");

        const data: MessageData = {
            content: input.content,
            created_at: new Date(),
            sender: MessageSender.Barkbus,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.SMS,
            contact: Message.getContact(input.phone, input.secondaryName),
            appointment_id: input.appointmentId,
            suggested_appointment_id: input.suggestedAppointmentId,
            ...Message.getMediaFields(input.files)
        };
        return await MessageRepository.add(data, input.collectionType, input.parentId);
    }
    static async createNote(input: MessageInputData) {
        const data: MessageData = {
            content: input.content,
            created_at: new Date(),
            sender: MessageSender.Barkbus,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.Note,
            ...Message.getMediaFields(input.files)
        };
        return await MessageRepository.add(data, input.collectionType, input.parentId);
    }
    static async createManagerNote(input: MessageInputData) {
        const data: MessageData = {
            content: input.content,
            created_at: new Date(),
            sender: MessageSender.Barkbus,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.ManagerNote,
            ...Message.getMediaFields(input.files)
        };
        return await MessageRepository.add(data, input.collectionType, input.parentId);
    }
    static async createActivity(input: MessageInputData) {
        if (!input.outcome)
            throw new Error("Outcome is required for activity messages");

        const data: MessageData = {
            content: input.content,
            created_at: new Date(),
            sender: MessageSender.Barkbus,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.Activity,
            outcome: input.outcome
        };
        return await MessageRepository.add(data, input.collectionType, input.parentId);
    }
    static async createImpersonatedSms(customerId: string, phone: string, content: string) {
        const data: MessageData = {
            content,
            created_at: new Date(),
            sender: MessageSender.Customer,
            user_id: DeviceStorageCache.getNonEmptyCurrentUserUid(),
            type: MessageType.SMS,
            contact: {
                phone,
                type: MessageContactType.Main
            },
            media_urls: [],
            media_types: []
        };
        return await MessageRepository.add(data, MessageParentType.Customers, customerId);
    }
    private static async add(message: MessageData, collectionType: MessageParentType, parentId: string) {
        const collection = getMessagesCollection(collectionType, parentId);
        const newDocument = await addDoc(collection, message);
        return new Message(newDocument.id, collectionType, parentId, message);
    }
    static async update(collectionType: MessageParentType, parentId: string, messageId: string, data: UpdateData<MessageData>) {
        const collection = getMessagesCollection(collectionType, parentId);
        const document = doc(collection, messageId);
        await updateDoc(document, data);
    }
    static async tagPets(customerId: string, messageId: string, tags: MessageTagPetData[]) {
        const collection = getMessagesCollection(MessageParentType.Customers, customerId);
        const document = doc(collection, messageId);
        const data: UpdateData<MessageData> = { "tags.pets": tags };
        await updateDoc(document, data);
    }
    static async deletePetsTag(collectionType: MessageParentType, parentId: string, messageId: string) {
        const collection = getMessagesCollection(collectionType, parentId);
        const document = doc(collection, messageId);
        const data: UpdateData<MessageData> = { "tags.pets": deleteField() };
        await updateDoc(document, data);
    }
    static async deleteStageTag(collectionType: MessageParentType, parentId: string, messageId: string) {
        const collection = getMessagesCollection(collectionType, parentId);
        const document = doc(collection, messageId);
        const data: UpdateData<MessageData> = { "tags.stage": deleteField() };
        await updateDoc(document, data);
    }
    static async remove(collectionType: MessageParentType, parentId: string, messageId: string) {
        const collection = getMessagesCollection(collectionType, parentId);
        const document = doc(collection, messageId);
        await deleteDoc(document);
    }
    static async searchAutomatedActivities(fromDate: LocalDate, toDate: LocalDate) {
        const collection = messagesCollectionGroup();
        const snapshot = await getDocs(query(collection,
            where("created_at", ">=", fromDate.toDayStart()),
            where("created_at", "<=", toDate.toDayEnd()),
            where("type", "==", MessageType.AutomatedActivity),
            orderBy("created_at", "desc")
        ));
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchCallSummaries(fromDate: LocalDate, toDate: LocalDate) {
        const collection = messagesCollectionGroup();
        const snapshot = await getDocs(query(collection,
            where("created_at", ">=", fromDate.toDayStart()),
            where("created_at", "<=", toDate.toDayEnd()),
            where("type", "==", MessageType.CallSummary),
            orderBy("created_at", "desc")
        ));
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchActivities(fromDate: LocalDate, toDate: LocalDate, pageSize?: number, afterDocument?: Document) {
        const collection = messagesCollectionGroup();
        let customQuery = query(collection,
            where("created_at", ">=", fromDate.toDayStart()),
            where("created_at", "<=", toDate.toDayEnd()),
            where("type", "==", MessageType.Activity),
            orderBy("created_at", "desc")
        );

        if (pageSize)
            customQuery = query(customQuery, limit(pageSize));

        if (afterDocument)
            customQuery = query(customQuery, startAfter(afterDocument));

        const snapshot = await getDocs(customQuery);
        return {
            messages: snapshot.docs.map(x => mapEntity(x)),
            lastDocument: snapshot.docs.length ? snapshot.docs[snapshot.docs.length - 1] : undefined
        };
    }
    static async searchCustomerChurnActivities(fromDate: LocalDate, toDate: LocalDate, pageSize?: number, afterDocument?: Document) {
        const collection = messagesCollectionGroup();
        let customQuery = query(collection,
            where("created_at", ">=", fromDate.toDayStart()),
            where("created_at", "<=", toDate.toDayEnd()),
            where("type", "==", MessageType.Activity),
            orderBy("created_at", "desc")
        );

        if (afterDocument)
            customQuery = query(customQuery, startAfter(afterDocument));

        const snapshot = await getDocs(customQuery);
        const churnActivities = [
            activityOutcomeOptions.customer_churn_moved,
            activityOutcomeOptions.customer_churn_pet_passed,
            activityOutcomeOptions.customer_churn_price,
            activityOutcomeOptions.customer_churn_service_issue,
            activityOutcomeOptions.customer_churn_stylist_departure,
            activityOutcomeOptions.customer_churn_other,
        ];

        const filteredDocs = snapshot.docs.filter(doc => {
            const data = doc.data();
            return data.outcome && churnActivities.includes(data.outcome);
        });

        const paginatedDocs = pageSize ? filteredDocs.slice(0, pageSize) : filteredDocs;

        return {
            messages: paginatedDocs.map(x => mapEntity(x)),
            lastDocument: paginatedDocs.length ? paginatedDocs[paginatedDocs.length - 1] : undefined
        };
    }
    static async searchByDateAndUser(localDate: LocalDate, userUid: string) {
        const collection = messagesCollectionGroup();
        const snapshot = await getDocs(query(collection,
            where("created_at", ">=", localDate.toDayStart()),
            where("created_at", "<=", localDate.toDayEnd()),
            where("user_id", "==", userUid),
            orderBy("created_at", "desc")
        ));
        return snapshot.docs.map(x => mapEntity(x));
    }
    static listenChanges(
        collectionType: MessageParentType,
        parentId: string,
        documentCount: number,
        typeFilter: MessageTypeFilter,
        callback: (messages: Message[]) => void,
        onlyWithAttachments?: boolean): () => void {
        const reference = getMessagesCollection(collectionType, parentId);
        const constraints = [];

        switch (typeFilter) {
            case MessageTypeFilter.All:
                constraints.push(where("type", "in", Message.allVisibleTypes()));
                break;
            case MessageTypeFilter.Messages:
                constraints.push(where("type", "in", Message.smsCompatibleTypes()));
                break;
            case MessageTypeFilter.Notes:
                constraints.push(where("type", "==", MessageType.Note));
                break;
            case MessageTypeFilter.ManagerNotes:
                constraints.push(where("type", "==", MessageType.ManagerNote));
                break;
            case MessageTypeFilter.Activity:
                constraints.push(where("type", "==", MessageType.Activity));
                break;
            case MessageTypeFilter.GroomerView:
                constraints.push(where("type", "in", [MessageType.SMS, MessageType.Note]));
                break;
            default:
                shouldBeUnreachable(typeFilter);
        }

        if (onlyWithAttachments) {
            constraints.push(where("has_media", "==", true));
        }

        constraints.push(orderBy("created_at", "desc"));
        constraints.push(limit(documentCount));
        const changesQuery = query(reference, ...constraints);
        return onSnapshot(changesQuery, snapshot => {
            const messages: Message[] = [];
            snapshot.forEach(x => messages.push(mapEntity(x)));
            callback(messages);
        });
    }
    static listenChangesWithMedia(
        parentId: string,
        callback: (messages: Message[]) => void) {
        const reference = getMessagesCollection(MessageParentType.Customers, parentId);
        const constraints = [
            where("has_media", "==", true)
        ];
        const changesQuery = query(reference, ...constraints);
        return onSnapshot(changesQuery, snapshot => {
            const messages: Message[] = [];
            snapshot.forEach(x => messages.push(mapEntity(x)));
            callback(messages);
        });
    }
    static async searchWithTaggedImages(parentId: string) {
        const collection = getMessagesCollection(MessageParentType.Customers, parentId);
        const customQuery = query(collection,
            where("tags", "!=", null)
        );

        const snapshot = await getDocs(customQuery);
        return snapshot.docs.map(x => mapEntity(x));

    }
    static get notify() {
        const getName = (fullName: string) => fullName.split(" ")[0];
        return {
            groomerOnTheirWay: async (appointment: Appointment) => {
                const content = `Your Barkbus Pet Stylist ${getName(appointment.groomer.name)} is on their way and will arrive in approximately ${appointment.minutes_until_arrival} ${appointment.minutes_until_arrival === 1 ? "minute" : "minutes"}. See you soon!`;
                await MessageRepository.createSms({
                    collectionType: MessageParentType.Customers,
                    parentId: appointment.customer.id,
                    content,
                    phone: appointment.customer.phone
                });
            },
            groomerHasArrived: async (appointment: Appointment) => {
                const content = `Hi ${getName(appointment.customer.name)}! Your Barkbus Pet Stylist ${getName(appointment.groomer.name)} has arrived!`;
                await MessageRepository.createSms({
                    collectionType: MessageParentType.Customers,
                    parentId: appointment.customer.id,
                    content,
                    phone: appointment.customer.phone
                });
            },
            completeGrooming: async (appointment: Appointment, pets: Pet[]) => {
                const appointmentPets = pets.filter(x => appointment.selected_pets.map(y => y.petId).includes(x.id));
                const content = `Hi ${getName(appointment.customer.name)} -- I have finished ${formatNames(appointmentPets.map(x => x.name))}'s 'Spaw' Day!`;
                await MessageRepository.createSms({
                    collectionType: MessageParentType.Customers,
                    parentId: appointment.customer.id,
                    content,
                    phone: appointment.customer.phone
                });
            }
        };
    }
}