import { useState, useCallback, useMemo, useEffect } from "react";
import TagManager from "react-gtm-module";
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import * as Sentry from "@sentry/react";
import Swal from "sweetalert2";
import { TimeZone } from "@marathon/common/timeZoneHelper";
import LocalDate from "@marathon/common/LocalDate";
import { CustomerVerificationMode, OnlineBookingFlowType, TimeFilterOptionKey, errorMessages, leadOutcomes, mixPanelEvent } from "@marathon/common/constants";
import { BookingSuggestion } from "@marathon/common/api/BookingSuggestion";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { Recurrence, WEEKLY_FREQUENCY_TYPE } from "@marathon/common/entities/Recurrence";
import { ConversionName, Customer, CustomerAddressData, CustomerData } from "@marathon/common/entities/Customer";
import { CustomerRepository } from "@marathon/client-side/repositories/CustomerRepository";
import { PetRepository } from "@marathon/client-side/repositories/PetRepository";
import { bookAppointment } from "@marathon/client-side/helpers/bookingHelper";
import { defaultValues as defaultPetValues, PetInput } from "@marathon/client-side/entities/PetInput";
import { authentication } from "@marathon/web/firebaseApp";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { SecondaryAddressInput, SecondaryAddressRepository } from "@marathon/client-side/repositories/SecondaryAddressRepository";
import OnlineBookingStep3Appointments from "./OnlineBookingStep3Appointments";
import OnlineBookingStep3PetForm from "./OnlineBookingStep3PetForm";
import OnlineBookingStep3PetSelection from "./OnlineBookingStep3PetSelection";
import FindSuggestions from "../shared/FindSuggestions";
import ConfirmAppointment from "../shared/ConfirmAppointment";
import ConfirmSafety from "../shared/ConfirmSafety";
import AppointmentOptions, { ONE_TIME_TYPE, RECURRENT_TYPE } from "../shared/AppointmentOptions";
import ThankYou from "../shared/ThankYou";
import { useFetchSuggestions } from "../shared/useFetchSuggestions";
import steps from "@marathon/common/onlineBookingSteps";
import { useOnlineBookingContext } from "../OnlineBookingContext";
import { useTracker } from "../shared/tracker/useTracker";
import { validateForm } from "../PetForm";
import ReferFriend from "./ReferFriend";
import NotificationSettings from "./NotificationSettings";
import ManageAccount from "./ManageAccount";
import { GoogleAddressValue, getAddressByGoogleAutocomplete } from "@marathon/web/helpers/addressHelper";
import { isWithinServiceAreaForHub } from "@marathon/common/mapHelper";
import PetInfo from "./PetInfo";
import { SecondaryAddress, SecondaryAddressStatus } from "@marathon/common/entities/SecondaryAddress";
import CardInfo from "./CardInfo";
import { getIsAuthenticated } from "../shared/authHelper";
import ReturningNewAddressForm from "./ReturningNewAddressForm";
import ReturningEditAddressForm from "./ReturningEditAddressForm";
import AppointmentDetail from "./AppointmentDetail";
import AppointmentChange from "./AppointmentChange";
import CancelAppointmentModal from "../shared/CancelAppointmentModal";
import { Appointment, AppointmentData } from "@marathon/common/entities/Appointment";
import CreatePaymentMethod from "../shared/CreatePaymentMethod";
import ThankYouAddCard from "../shared/ThankYouAddCard";
import { DiscountCode } from "@marathon/common/entities/DiscountCode";
import { CreditCard } from "@marathon/common/entities/CreditCard";
import { Pet } from "@marathon/common/entities/Pet";
import { Service } from "@marathon/common/entities/Service";
import { Credit } from "@marathon/common/entities/Credit";

interface Props {
    stepNumber: number,
    substepNumber: number | null,
    customer: Customer,
    pets: Pet[],
    setPets: (pets: Pet[]) => void,
    selectedServices: { petId: string, serviceId: string }[],
    setSelectedServices: (selectedServices: { petId: string, serviceId: string }[]) => void,
    customerCredits: Credit[],
    customerUpcomingAppointments: AppointmentOrOccurrence[],
    customerPastAppointments: AppointmentOrOccurrence[],
    onCustomerUpdate: (updatedCustomer: Customer) => void,
    goToStep: (step: number, substep?: number, shouldReplace?: boolean | undefined) => void,
    isBlockedUser: boolean,
    handleCancelAppointment: (appointment: AppointmentOrOccurrence) => Promise<void>,
    setIsBlockedUser: React.Dispatch<React.SetStateAction<boolean>>,
    addresses: (SecondaryAddress | CustomerAddressData)[],
    setAddresses: (addresses: (SecondaryAddress | CustomerAddressData)[]) => void,
    selectedAddress?: SecondaryAddress | CustomerAddressData,
    handleSelectedAddressChange: (address: SecondaryAddress | CustomerAddressData) => void,
    discountCode?: DiscountCode,
    handleUpdateAppointment: (appointmentOrOccurrence: AppointmentOrOccurrence, toUpdate: Partial<AppointmentData>) => Promise<void>,
    selectedAppointment: AppointmentOrOccurrence | null,
    setSelectedAppointment: (appointmentOrOccurrence: AppointmentOrOccurrence | null) => void
}

export default function OnlineBookingInnerContainer({
    stepNumber,
    substepNumber,
    customer,
    pets,
    setPets,
    selectedServices,
    setSelectedServices,
    customerCredits,
    customerUpcomingAppointments,
    customerPastAppointments,
    onCustomerUpdate,
    goToStep,
    isBlockedUser,
    handleCancelAppointment,
    setIsBlockedUser,
    addresses,
    setAddresses,
    selectedAddress,
    handleSelectedAddressChange,
    discountCode,
    handleUpdateAppointment,
    selectedAppointment,
    setSelectedAppointment
}: Props) {
    const [specificDate, setSpecificDate] = useState<LocalDate | null>(null);
    const [selectedGroomerId, setSelectedGroomerId] = useState<string | null>(null);
    const [selectedTimeFilter, setSelectedTimeFilter] = useState<TimeFilterOptionKey | null>(null);

    const [cards, setCards] = useState<CreditCard[]>([]);
    const [isLoadingCreditCards, setIsLoadingCreditCards] = useState(false);
    const [editAddressInput, setEditAddressInput] = useState<CustomerAddressData | SecondaryAddressInput>(selectedAddress ?? Customer.getDefaultAddress());
    const [newAddressInput, setNewAddressInput] = useState<SecondaryAddressInput>(Customer.getDefaultAddress());
    const [selectedPet, setSelectedPet] = useState<Pet | null>(null);
    const [selectedCard, setSelectedCard] = useState<CreditCard | null>(null);

    const [isUpdatingConsent, setIsUpdatingConsent] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showCreditCardSuccessMessage, setShowCreditCardSuccessMessage] = useState(false);

    const [newPet, setNewPet] = useState(defaultPetValues);
    const [isSavingPet, setIsSavingPet] = useState(false);
    const [petFormError, setPetFormError] = useState("");

    const [bookedSuggestion, setBookedSuggestion] = useState<BookingSuggestion | null>(null);
    const [appointmentType, setAppointmentType] = useState<typeof ONE_TIME_TYPE | typeof RECURRENT_TYPE>(RECURRENT_TYPE);
    const [frequency, setFrequency] = useState<{ interval: number, type: string }>({ interval: 4, type: WEEKLY_FREQUENCY_TYPE });
    const [appointmentNotes, setAppointmentNotes] = useState("");
    const [appointment, setAppointment] = useState<Appointment | Recurrence | null>(null);

    const [isSavingAddress, setIsSavingAddress] = useState(false);
    const [isRemovingAddress, setIsRemovingAddress] = useState(false);
    const [isGeocoding, setIsGeocoding] = useState(false);
    const [isDisablingCard, setIsDisablingCard] = useState(false);
    const [isRemovingAppointment, setIsRemovingAppointment] = useState(false);
    const [isTrackingStep, setIsTrackingStep] = useState(false);

    const { pricingPresets, breeds, groomers, hubs, services, mobileServiceFee, discounts, isFromSms, clickId } = useOnlineBookingContext();

    if (!mobileServiceFee)
        throw new Error("Mobile service fee configuration is required at this point");

    const getCustomerTimeZone = useCallback(() => {
        return hubs.find(x => x.id === selectedAddress?.drive_time?.hub_id)?.time_zone ?? TimeZone.PacificTime;
    }, [hubs, selectedAddress?.drive_time?.hub_id]);

    const { suggestions, isFetchingSuggestions } = useFetchSuggestions({
        customerId: customer.id,
        selectedServices,
        specificDate: specificDate || undefined,
        selectedGroomerId: selectedGroomerId || undefined,
        selectedTimeFilter: selectedTimeFilter || undefined,
        services,
        selectedAddress: selectedAddress || customer.address,
        isAuthenticated: getIsAuthenticated()
    });

    const getTotalServicePrice = useCallback(() => {
        return Service.getServicesFinalPrice(selectedServices, pets, customer, mobileServiceFee);
    }, [selectedServices, pets, customer, mobileServiceFee]);

    const getServiceNames = useCallback(() => {
        return Service.getServicesLabel(services, selectedServices.map(x => x.serviceId));
    }, [services, selectedServices]);

    const getFindSuggestionTrackEventProperties = () => {
        return { new_user: false, groom_price: getTotalServicePrice(), service: getServiceNames() };
    };

    const handleUpdateConsent = async (dogsTextOk: boolean, marketingTextOk: boolean, marketingEmailOk: boolean, postcardOk: boolean) => {
        setIsUpdatingConsent(true);
        onCustomerUpdate(
            new Customer(customer.id, {
                ...customer.toData(),
                text_ok: dogsTextOk,
                marketing_sms_opt_out: !marketingTextOk,
                marketing_email_opt_out: !marketingEmailOk,
                postcard_opt_out: !postcardOk

            })
        );
        await CustomerRepository.updateFromOnlineBooking(customer.id, {
            text_ok: dogsTextOk,
            marketing_sms_opt_out: !marketingTextOk,
            marketing_email_opt_out: !marketingEmailOk,
            postcard_opt_out: !postcardOk
        });
        setIsUpdatingConsent(false);
        setShowSuccessMessage(true);
        goToStep(steps.returning.manageAccount);
    };

    const tryUpdateLeadInfoOutcome = async (initialStatus: string, finalStatus: string) => {
        if (!customer.lead_info?.outcome || customer.lead_info.outcome === initialStatus) {
            try {
                await CustomerRepository.updateFromOnlineBooking(customer.id, { "lead_info.outcome": finalStatus });
                onCustomerUpdate(
                    new Customer(customer.id, {
                        ...customer.toData(),
                        lead_info: { ...customer.lead_info, outcome: finalStatus }
                    })
                );
            }
            catch (error) {
                console.error(error);
                Sentry.captureException(error);
            }
        }
    };

    const getServiceIds = useCallback(() => {
        return selectedServices.map(x => x.serviceId);
    }, [selectedServices]);

    const filteredGroomers = useMemo(() => {
        const serviceIds = getServiceIds();
        return groomers.filter(x => x.hub_id === selectedAddress?.drive_time?.hub_id && !x.hasRestrictions(serviceIds));
    }, [getServiceIds, groomers, selectedAddress?.drive_time?.hub_id]);

    const getInfo = useMemo(() => {
        return {
            customer: {
                name: customer.fullname(),
                email: customer.email,
                petQuantity: pets.length,
                address: customer.formattedAddress(),
                authenticationType: authentication.currentUser?.email
                    ? CustomerVerificationMode.emailLink
                    : CustomerVerificationMode.phoneCode
            },
            dogs: pets.map(x => {
                return {
                    breedName: breeds.find(y => y.id === x.breed_id)?.name,
                    age: x.getAge() || undefined,
                    gender: x.gender,
                    weight: x.weight,
                };
            }),
            servicePrice: getTotalServicePrice(),
            serviceNames: getServiceNames(),
            appointment: {
                arrivalTime: bookedSuggestion?.arrivalTime,
                timeZone: bookedSuggestion?.timeZone
            },
            isFromSms: isFromSms,
        };
    }, [bookedSuggestion?.arrivalTime, bookedSuggestion?.timeZone, breeds, customer, getServiceNames, getTotalServicePrice, isFromSms, pets]);

    const trackEvent = useTracker({ flowType: OnlineBookingFlowType.returningCustomer, stepNumber, substepNumber: substepNumber || undefined, info: getInfo });

    const handleSavePet = async () => {
        if (!validateForm(newPet, setPetFormError))
            return;

        setIsSavingPet(true);

        try {
            const breed = breeds.find(x => x.id === newPet.breed_id);
            if (!breed)
                throw new Error(`Breed not valid ${newPet.breed_id}`);

            if (!newPet.weight)
                throw new Error("Weight is required at this point");

            const customerHub = hubs.find(x => x.id === customer.address.drive_time?.hub_id);
            const pricingPreset = pricingPresets.find(x => x.id === customerHub?.pricing_preset_id);
            if (!pricingPreset)
                throw new Error("PricingPreset required at this point");

            newPet.services = PetRepository.getServices(breed, newPet.weight, pricingPreset);
            const petAdded = await PetRepository.create(customer.id, newPet);
            setNewPet(defaultPetValues);
            setPets([...pets, petAdded]);
            setSelectedServices([
                ...selectedServices,
                { petId: petAdded.id, serviceId: petAdded.getDefaultServiceId() }
            ]);
        }
        catch (error) {
            console.error(error);
            Sentry.captureException(error);
            setPetFormError("Something went wrong adding the new pet, please refresh screen and try again");
        }

        setIsSavingPet(false);
    };

    const handleNewPetChange = (value: PetInput) => {
        setNewPet(prev => ({ ...prev, ...value }));
    };

    const bookAppointmentInternal = async () => {
        try {
            if (!bookedSuggestion)
                throw new Error("Suggestion is required at this point");

            const groomer = groomers.find(x => x.id === bookedSuggestion.groomer.id);
            if (!groomer)
                throw new Error(`Groomer ${bookedSuggestion.groomer.id} not found`);

            if (!customer)
                throw new Error("Customer is required at this point");

            const blackList = await checkCustomerIsBlacklist(customer);
            if (blackList) {
                setIsBlockedUser(true);
                throw new Error("Customer not valid for online booking");
            }

            if (clickId) {
                await CustomerRepository.updateFromOnlineBooking(customer.id, { ads_click_id: { ...clickId, conversion_name: ConversionName.BookedOnline } });
            }

            const isSecondaryAddress = selectedAddress && "id" in selectedAddress;
            const secondaryAddress = isSecondaryAddress ? selectedAddress : undefined;

            const bookedAppointment = await bookAppointment({
                suggestion: bookedSuggestion,
                groomer,
                customer,
                pets,
                services,
                isFromSignup: false,
                assignments: selectedServices,
                mobileServiceFee,
                isFromSms,
                isFromOnlineBooking: true,
                frequency: appointmentType === RECURRENT_TYPE ? frequency : null,
                notesFromCustomer: appointmentNotes,
                discounts,
                hubs,
                customerCredits,
                secondaryAddress,
                discountCode
            });
            setAppointment(bookedAppointment);
        }
        catch (error) {
            console.error(error);
            Sentry.captureException(error);
            Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again." });
            throw error;
        }
    };

    const checkCustomerIsBlacklist = async (customer: Customer) => {
        const freshCustomerResponse = await CallableFunctions.public.getCurrentCustomer(customer.phone);
        return freshCustomerResponse?.customer.blacklist;
    };

    const handleAddressChange = (address: CustomerAddressData) => {
        onCustomerUpdate(
            new Customer(customer.id, {
                ...customer.toData(),
                address
            })
        );
        const [, ...secondaryAddresses] = addresses;
        setAddresses([address, ...secondaryAddresses]);
    };

    const handleAddressAutocompleteChange = async (selected: { value: GoogleAddressValue } | null) => {
        if (selected) {
            try {
                setIsGeocoding(true);
                const address = await getAddressByGoogleAutocomplete(selected, hubs);
                setEditAddressInput({ ...("id" in editAddressInput ? { id: editAddressInput.id, alias: editAddressInput.alias } : {}), ...address });
                setIsGeocoding(false);
            } catch (error) {
                console.error(error);
                Sentry.captureException(error);
                Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again." });
            }
        }
    };

    const handleNewAddressAutocompleteChange = async (selected: { value: GoogleAddressValue } | null) => {
        if (selected) {
            try {
                setIsGeocoding(true);
                const address = await getAddressByGoogleAutocomplete(selected, hubs);
                setNewAddressInput(address);
                setIsGeocoding(false);
            } catch (error) {
                console.error(error);
                Sentry.captureException(error);
                Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again." });
            }
        }
    };

    const handleUpdateSecondaryAddress = async (address: SecondaryAddressInput) => {
        if (!address.id) {
            console.warn("Address id is required");
            return;
        }

        await SecondaryAddressRepository.update(customer.id, address.id, { ...address, status: SecondaryAddressStatus.active });
        const updatedAddresses = addresses.map((x) => {
            if (x instanceof SecondaryAddress && x.id === address.id) {
                return { id: address.id, ...SecondaryAddressRepository.fromInput(address) } as SecondaryAddress;
            }
            return x;
        });
        setAddresses(updatedAddresses);
    };

    const handleUpdatePrimaryAddress = async (address: CustomerAddressData) => {
        const toUpdate: Partial<CustomerData> = {
            address
        };
        await CustomerRepository.updateFromOnlineBooking(customer.id, { ...toUpdate });
        handleAddressChange(address);
    };

    const handleEditAddressNextButton = async () => {
        try {
            setIsSavingAddress(true);

            if (!editAddressInput.place_id) {
                throw new Error(`Customer ${customer.id} has no place_id in address ${editAddressInput.address1}`);
            }

            const hub = hubs.find(x => x.id === editAddressInput.drive_time?.hub_id);
            if (!isWithinServiceAreaForHub(Customer.getCoordinates(editAddressInput), hub)) {
                return;
            }

            if ("id" in editAddressInput) {
                await handleUpdateSecondaryAddress(editAddressInput as SecondaryAddress);
            } else {
                await handleUpdatePrimaryAddress(editAddressInput as CustomerAddressData);
            }
        }
        catch (error) {
            console.error(error);
            Sentry.captureException(error);
            Swal.fire({ title: errorMessages.try_again_or_call_us });
            setIsSavingAddress(false);
            return;
        }

        setIsSavingAddress(false);
        goToStep(steps.returning.manageAccount);
    };

    const handleNewAddressNextButton = async () => {
        try {
            setIsSavingAddress(true);

            if (!newAddressInput.place_id) {
                throw new Error(`Customer ${customer.id} has no place_id in address ${newAddressInput.address1}`);
            }

            const hub = hubs.find(x => x.id === newAddressInput.drive_time?.hub_id);
            if (!isWithinServiceAreaForHub(Customer.getCoordinates(newAddressInput), hub)) {
                return;
            }

            const newSecondaryAddress = await SecondaryAddressRepository.create(customer.id, { ...newAddressInput, status: SecondaryAddressStatus.active });
            setAddresses([...addresses, newSecondaryAddress]);
            setNewAddressInput(Customer.getDefaultAddress());
        }
        catch (error) {
            console.error(error);
            Sentry.captureException(error);
            Swal.fire({ title: errorMessages.try_again_or_call_us });
            setIsSavingAddress(false);
            return;
        }

        setIsSavingAddress(false);
        goToStep(steps.returning.manageAccount);
    };

    const handleRemoveAddress = async (addressId: string) => {
        setIsRemovingAddress(true);
        try {
            await SecondaryAddressRepository.remove(customer.id, addressId);
            setAddresses(addresses.filter(x => !("id" in x) || x.id !== addressId));
        } catch (error) {
            console.error(error);
        }
        goToStep(steps.returning.manageAccount);
        setIsRemovingAddress(false);
    };

    const handleDisableCardClick = async (creditCardId: string) => {
        setIsDisablingCard(true);
        const response = await CallableFunctions.square.disableCustomerSquareCard(creditCardId);
        if (response.status === "success") {
            setCards(cards.filter(x => x.id !== creditCardId));
            goToStep(steps.returning.manageAccount);
        }
        else {
            Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again later." });
        }
        setIsDisablingCard(false);
    };

    const handleCardTokenized = async (token: string) => {
        const newCard = await CallableFunctions.square.createCustomerSquareCard(token);
        if (newCard) {
            setCards([...cards, newCard]);
            goToStep(steps.returning.manageAccount);
        } else {
            Swal.fire({ title: "Sorry, an unexpected error has occurred. Please try again later." });
        }
    };

    const handleCancelAppointmentClick = async (appointment: AppointmentOrOccurrence) => {
        setIsRemovingAppointment(true);
        await handleCancelAppointment(appointment);
        setIsRemovingAppointment(false);
        goToStep(steps.returning.welcome);
    };

    const handleStartBookingSession = async () => {
        setIsTrackingStep(true);
        try {
            const toUpdate: Partial<CustomerData> = {
                open_online_booking_session: {
                    outcome: leadOutcomes.open_find_appointment,
                    started_at: new Date()
                }
            };
            await CustomerRepository.updateFromOnlineBooking(customer.id, toUpdate);
            onCustomerUpdate(
                new Customer(customer.id, {
                    ...customer.toData(),
                    open_online_booking_session: toUpdate.open_online_booking_session
                })
            );
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
        goToStep(steps.returning.suggestions);
        setIsTrackingStep(false);
    };

    const handleTrackNextStep = async (stepNumber: number, openSessionOutcome?: string) => {
        setIsTrackingStep(true);
        try {
            if (openSessionOutcome) {
                const toUpdate: Partial<CustomerData> = {
                    open_online_booking_session: {
                        outcome: openSessionOutcome,
                        started_at: customer.open_online_booking_session?.started_at || new Date()
                    }
                };
                await CustomerRepository.updateFromOnlineBooking(customer.id, toUpdate);
                onCustomerUpdate(
                    new Customer(customer.id, {
                        ...customer.toData(),
                        open_online_booking_session: toUpdate.open_online_booking_session
                    })
                );
            }
            else {
                await CustomerRepository.cleanOnlineBookingSession(customer.id);
            }
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
        goToStep(stepNumber);
        setIsTrackingStep(false);
    };

    const handlePaymentMethodAddedSuccess = () => {
        setShowCreditCardSuccessMessage(true);
        goToStep(steps.returning.thankYou);
    };

    useEffect(() => {
        if (appointment?.id) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const dataLayerData: any = {
                event: "Reservation",
                reservationId: `[${appointment.id}]`,
                reservationPrice: `[${appointment.finalPrice()}]`,
                email: customer.email,
            };
            if (clickId) {
                dataLayerData[clickId.type] = clickId.value;
            }
            TagManager.dataLayer({
                dataLayer: dataLayerData,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment, customer.email]);

    useEffect(() => {
        const loadCards = async () => {
            try {
                setIsLoadingCreditCards(true);
                setCards(await CallableFunctions.square.getCustomerSquareCards());
            } catch (error) {
                console.error(error);
                Sentry.captureException(error);
            }
            finally {
                setIsLoadingCreditCards(false);
            }
        };

        loadCards();
    }, []);

    return (
        <>
            {stepNumber === steps.returning.welcome &&
                <>
                    {substepNumber === steps.returning.welcomeSubsteps.petSelection &&
                        <OnlineBookingStep3PetSelection
                            customer={customer}
                            pets={pets}
                            services={services}
                            handleChangeSelectedService={(petId: string, serviceId: string) => setSelectedServices(selectedServices.map(x => x.petId === petId ? { ...x, serviceId } : x))}
                            selectedServices={selectedServices}
                            onPetSelectionChange={x => setSelectedServices(x)}
                            onNext={() => handleStartBookingSession()}
                            isLoading={false}
                            handleAddPetClick={() => goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.petForm)}
                            mobileServiceFee={mobileServiceFee}
                            credits={customerCredits}
                            addresses={addresses}
                            selectedAddress={selectedAddress}
                            handleSelectedAddressChange={handleSelectedAddressChange}
                            hubs={hubs}
                            discounts={discounts}
                            discountCode={discountCode}
                            isTrackingStep={isTrackingStep}
                        />}
                    {substepNumber === steps.returning.welcomeSubsteps.petForm &&
                        <OnlineBookingStep3PetForm
                            onNext={async () => {
                                await handleSavePet();
                                goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.petSelection);
                            }}
                            customerPetsAmount={pets.length ?? 0}
                            breeds={breeds}
                            isSavingPet={isSavingPet}
                            pet={newPet}
                            handlePetChange={handleNewPetChange}
                            formError={petFormError}
                        />}
                    {substepNumber === steps.returning.welcomeSubsteps.referral &&
                        <ReferFriend
                            customer={customer}
                        />}
                    {substepNumber === steps.returning.welcomeSubsteps.upcomingAppointmentDetails && selectedAppointment &&
                        <AppointmentDetail
                            appointment={selectedAppointment}
                            addresses={addresses}
                            groomers={groomers}
                            pets={pets}
                            services={services}
                            handleChangeAppointment={() => goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.changeAppointment)}
                            handleCancelAppointment={() => goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.cancelAppointment)}
                        />}
                    {substepNumber === steps.returning.welcomeSubsteps.changeAppointment && selectedAppointment &&
                        <AppointmentChange
                            appointmentOrOccurrence={selectedAppointment}
                            addresses={addresses}
                            pets={pets}
                            customer={customer}
                            services={services}
                            hubs={hubs}
                            breeds={breeds}
                            groomers={groomers}
                            goBack={() => goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.upcomingAppointmentDetails)}
                            handleUpdateAppointment={handleUpdateAppointment}
                        />}
                    {substepNumber === steps.returning.welcomeSubsteps.cancelAppointment && selectedAppointment &&
                        <CancelAppointmentModal
                            appointment={selectedAppointment}
                            isRemovingAppointment={isRemovingAppointment}
                            onCancelAppointment={handleCancelAppointmentClick}
                            goBack={() => goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.upcomingAppointmentDetails)}
                        />}
                    {!substepNumber &&
                        <OnlineBookingStep3Appointments
                            customer={customer}
                            pets={pets}
                            services={services}
                            customerUpcomingAppointments={customerUpcomingAppointments}
                            customerPastAppointments={customerPastAppointments}
                            onNext={(shouldReplace: boolean) => goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.petSelection, shouldReplace)}
                            isBlockedUser={isBlockedUser}
                            onReferralClick={() => goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.referral)}
                            onManageAccountClick={() => goToStep(steps.returning.manageAccount)}
                            credits={customerCredits}
                            onUpcomingAppointmentClick={(appointment: AppointmentOrOccurrence) => {
                                setSelectedAppointment(appointment);
                                goToStep(steps.returning.welcome, steps.returning.welcomeSubsteps.upcomingAppointmentDetails);
                            }}
                        />}

                </>}
            {stepNumber === steps.returning.manageAccount &&
                <>
                    {substepNumber === steps.returning.manageAccountSubsteps.newPetForm &&
                        <OnlineBookingStep3PetForm
                            onNext={async () => {
                                await handleSavePet();
                                goToStep(steps.returning.manageAccount);
                            }}
                            customerPetsAmount={pets.length ?? 0}
                            breeds={breeds}
                            isSavingPet={isSavingPet}
                            pet={newPet}
                            handlePetChange={handleNewPetChange}
                            formError={petFormError}
                        />}
                    {substepNumber === steps.returning.manageAccountSubsteps.petInfo && selectedPet &&
                        <PetInfo
                            pet={selectedPet}
                            breeds={breeds}
                        />}
                    {substepNumber === steps.returning.manageAccountSubsteps.createPaymentMethod &&
                        <CreatePaymentMethod
                            customer={customer}
                            onCardTokenized={handleCardTokenized}
                            hubs={hubs}
                        />}
                    {substepNumber === steps.returning.manageAccountSubsteps.editPaymentMethod && selectedCard &&
                        <CardInfo
                            card={selectedCard}
                            isDisablingCard={isDisablingCard}
                            onDisableCardClick={handleDisableCardClick}
                        />}
                    {substepNumber === steps.returning.manageAccountSubsteps.editAddress &&
                        <ReturningEditAddressForm
                            customer={customer}
                            address={editAddressInput}
                            handleChange={setEditAddressInput}
                            onAddressAutocompleteChange={handleAddressAutocompleteChange}
                            onNext={handleEditAddressNextButton}
                            onRemoveAddress={handleRemoveAddress}
                            isLoading={isSavingAddress}
                            isGeocoding={isGeocoding}
                            isRemoving={isRemovingAddress}
                        />}
                    {substepNumber === steps.returning.manageAccountSubsteps.newAddress &&
                        <ReturningNewAddressForm
                            customer={customer}
                            address={newAddressInput}
                            handleChange={setNewAddressInput}
                            onAddressAutocompleteChange={handleNewAddressAutocompleteChange}
                            onNext={handleNewAddressNextButton}
                            isLoading={isSavingAddress}
                            isGeocoding={isGeocoding}
                        />
                    }
                    {substepNumber === steps.returning.manageAccountSubsteps.notificationSettings &&
                        <NotificationSettings
                            customer={customer}
                            handleUpdateConsent={handleUpdateConsent}
                            isUpdatingConsent={isUpdatingConsent}
                        />}
                    {!substepNumber &&
                        <ManageAccount
                            addresses={addresses}
                            cards={cards}
                            pets={pets}
                            isLoadingCards={isLoadingCreditCards}
                            onNewAddressClick={() => {
                                setNewAddressInput(Customer.getDefaultAddress());
                                goToStep(steps.returning.manageAccount, steps.returning.manageAccountSubsteps.newAddress);
                            }}
                            onChangeAddressClick={(address) => {
                                setEditAddressInput(address);
                                goToStep(steps.returning.manageAccount, steps.returning.manageAccountSubsteps.editAddress);
                            }}
                            onNewPetClick={() => {
                                setNewPet(defaultPetValues);
                                goToStep(steps.returning.manageAccount, steps.returning.manageAccountSubsteps.newPetForm);
                            }}
                            onEditPetClick={(pet: Pet) => {
                                setSelectedPet(pet);
                                goToStep(steps.returning.manageAccount, steps.returning.manageAccountSubsteps.petInfo);
                            }}
                            onCreatePaymentMethodClick={() => {
                                goToStep(steps.returning.manageAccount, steps.returning.manageAccountSubsteps.createPaymentMethod);
                            }}
                            onEditCardClick={(card: CreditCard) => {
                                setSelectedCard(card);
                                goToStep(steps.returning.manageAccount, steps.returning.manageAccountSubsteps.editPaymentMethod);
                            }}
                            onNotificationSettingsClick={() => goToStep(steps.returning.manageAccount, steps.returning.manageAccountSubsteps.notificationSettings)}
                        />}
                </>}
            {stepNumber === steps.returning.suggestions &&
                <FindSuggestions
                    isLoading={isFetchingSuggestions}
                    suggestions={suggestions}
                    groomers={filteredGroomers}
                    specificDate={specificDate}
                    setSpecificDate={setSpecificDate}
                    selectedGroomerId={selectedGroomerId}
                    setSelectedGroomerId={setSelectedGroomerId}
                    selectedTimeFilter={selectedTimeFilter}
                    setSelectedTimeFilter={setSelectedTimeFilter}
                    onBookAppointmentClick={() => handleTrackNextStep(steps.returning.appointmentOptions, leadOutcomes.open_appointment_options)}
                    setBookedSuggestion={setBookedSuggestion}
                    tryUpdateLeadInfoOutcome={tryUpdateLeadInfoOutcome}
                    trackEvent={trackEvent}
                    trackEventData={{
                        eventName: mixPanelEvent.ob_return_dates,
                        properties: getFindSuggestionTrackEventProperties
                    }}
                    customerTimeZone={getCustomerTimeZone()}
                />}
            {stepNumber === steps.returning.appointmentOptions && bookedSuggestion &&
                <AppointmentOptions
                    customer={customer}
                    suggestion={bookedSuggestion}
                    onConfirmOptions={() => handleTrackNextStep(steps.returning.confirmation, leadOutcomes.open_confirmation)}
                    handleFrequency={x => setFrequency(x)}
                    tryUpdateLeadInfoOutcome={tryUpdateLeadInfoOutcome}
                    frequency={frequency}
                    appointmentType={appointmentType}
                    handleChangeAppointmentType={(type) => setAppointmentType(type)}
                    discounts={discounts}
                    hubs={hubs}
                    mobileServiceFee={mobileServiceFee}
                    discountCode={discountCode}
                    isTrackingStep={isTrackingStep}
                />}
            {stepNumber === steps.returning.confirmation && customer && bookedSuggestion &&
                <ConfirmAppointment
                    customer={customer}
                    suggestion={bookedSuggestion}
                    onConfirmBooking={() => handleTrackNextStep(steps.returning.thankYou)}
                    onGoToSafety={() => handleTrackNextStep(steps.returning.safety, leadOutcomes.open_safety_page)}
                    pets={pets}
                    services={services}
                    selectedServices={selectedServices}
                    mobileServiceFee={mobileServiceFee}
                    tryUpdateLeadInfoOutcome={tryUpdateLeadInfoOutcome}
                    appointmentNotes={appointmentNotes}
                    setAppointmentNotes={setAppointmentNotes}
                    bookAppointment={bookAppointmentInternal}
                    frequency={appointmentType === RECURRENT_TYPE ? frequency : null}
                    discounts={discounts}
                    credits={customerCredits}
                    selectedAddress={selectedAddress || customer.address}
                    hubs={hubs}
                    discountCode={discountCode}
                />}
            {stepNumber === steps.returning.safety &&
                <ConfirmSafety
                    customer={customer}
                    bookAppointment={bookAppointmentInternal}
                    onNext={() => goToStep(steps.returning.thankYou)}
                />}
            {stepNumber === steps.returning.thankYou &&
                <>
                    {substepNumber === steps.returning.thankYouSubsteps.createPaymentMethod &&
                        <ThankYouAddCard
                            customer={customer}
                            flowType={OnlineBookingFlowType.returningCustomer}
                            setCards={setCards}
                            cards={cards}
                            handlePaymentMethodAddedSuccess={handlePaymentMethodAddedSuccess}
                            hubs={hubs}
                        />}
                    {!substepNumber &&
                        <ThankYou
                            appointment={appointment}
                            suggestion={bookedSuggestion}
                            pets={pets}
                            trackEvent={trackEvent}
                            flowType={OnlineBookingFlowType.returningCustomer}
                            hubs={hubs}
                            addNewPaymentMethod={() => goToStep(steps.returning.thankYou, steps.returning.thankYouSubsteps.createPaymentMethod)}
                            goToMyAccount={() => goToStep(steps.returning.welcome)}
                            cards={cards}
                        />}
                </>}
            <Snackbar open={showSuccessMessage} autoHideDuration={6000} onClose={() => setShowSuccessMessage(false)}>
                <Alert severity="success">
                    Thanks! Your preferences have been updated
                </Alert>
            </Snackbar>
            <Snackbar open={showCreditCardSuccessMessage} autoHideDuration={6000} onClose={() => setShowCreditCardSuccessMessage(false)}>
                <Alert severity="success">
                    Thanks! Your credit card has been added
                </Alert>
            </Snackbar>
        </>
    );
}
