import { DateTime } from "luxon";
import { Gender, GroomerSummary } from "@marathon/common/entities/Groomer";
import { TimeZone } from "@marathon/common/timeZoneHelper";
import LocalDate from "@marathon/common/LocalDate";
import { FunctionParamsBase } from "./FunctionParamsBase";
import { formatTime, formatTimeWindow } from "../timeFormatHelper";
import { ARRIVAL_WINDOW_HOURS } from "../entities/Appointment";
import { CustomerAddressData } from "../entities/Customer";
import { DayOfWeek } from "../constants";
import { SecondaryAddress } from "../entities/SecondaryAddress";

interface BookingSuggestion extends SuggestionTime {
    groomer: GroomerSummary;
    referencePlaceId: string;
    isForEmptyDay: boolean;
    weightedScore: number;
    driveTime?: {
        duration: {
            text: string,
            value: number
        }
    };
    distance?: number;
    interval?: number;
}

interface PotentialSuggestion {
    arrivalTime: Date;
    groomer: GroomerSummary;
    referencePlaceId: string;
    isForEmptyDay: boolean;
    isFirstAppointmentOfTheDay: boolean;
    distance: number;
}

interface SuggestionTime {
    arrivalTime: Date;
    showExactTime: boolean;
    timeZone: TimeZone;
}

enum SuggestionsRankingOptions {
    soonest = "Soonest",
    shortestDriveTime = "Shortest drive time",
    shortestDistance = "Shortest distance",
    weighted = "Best (weighted)"
}

enum SuggestionType {
    oneTime = "OneTime",
    recurring = "Recurring"
}

interface BookingSuggestionSearchParameters extends FunctionParamsBase {
    estimatedTime: number;
    customerId: string;
    serviceIds: string[];
    selectedAddress: CustomerAddressData | SecondaryAddress;
    specificDate?: LocalDate;
    groomerId?: string;
    timeRange?: { min: number, max: number };
    rankingCriteria?: SuggestionsRankingOptions;
    ignoreDistanceLimit?: true;
    daysOfWeek?: DayOfWeek[];
    groomerGender?: Gender;
}

class BookingSuggestionSorterHelper {
    static sort(suggestions: BookingSuggestion[], criteria: string) {
        switch (criteria) {
            case SuggestionsRankingOptions.soonest:
                suggestions.sortByFieldAscending(x => x.arrivalTime);
                break;
            case SuggestionsRankingOptions.shortestDriveTime:
                suggestions.sortByFieldAscending(x => x.driveTime?.duration.value);
                break;
            case SuggestionsRankingOptions.shortestDistance:
                suggestions.sortByFieldAscending(x => x.distance);
                break;
            default:
                suggestions.sortByFieldAscending(x => x.weightedScore);
                break;
        }
        return suggestions;
    }
}

export function getSuggestionArrivalTimeWithLabel(suggestion: SuggestionTime) {
    return (
        suggestion.showExactTime
            ? `at ${getSuggestionExactTime(suggestion)}`
            : `with a ${getSuggestionTimeWindow(suggestion)} arrival window`
    );
}

export function getSuggestionArrivalTimeWithStandaloneLabel(suggestion: SuggestionTime) {
    return (
        suggestion.showExactTime
            ? `${getSuggestionExactTime(suggestion)} Arrival Time`
            : `${getSuggestionTimeWindow(suggestion)} Arrival Window`
    );
}

export function getSuggestionArrivalTime(suggestion: SuggestionTime) {
    return (
        suggestion.showExactTime
            ? getSuggestionExactTime(suggestion)
            : getSuggestionTimeWindow(suggestion)
    );
}

export function getSuggestionTimeWindow(suggestion: SuggestionTime) {
    return (
        formatTimeWindow(
            suggestion.arrivalTime,
            DateTime.fromJSDate(suggestion.arrivalTime).plus({ hours: ARRIVAL_WINDOW_HOURS }).toJSDate(),
            suggestion.timeZone
        )
    );
}

export function getSuggestionExactTime(suggestion: SuggestionTime) {
    return formatTime(suggestion.arrivalTime, suggestion.timeZone);
}

export type { BookingSuggestion, BookingSuggestionSearchParameters, PotentialSuggestion, SuggestionTime };
export { BookingSuggestionSorterHelper, SuggestionsRankingOptions, SuggestionType };