import { configurationsCollection } from "@marathon/client-side/database";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { ConfigurationDocument, AiIntegration, AlgoliaConfiguration, MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";

export class ConfigurationRepository {
    static async getAiIntegration() {
        const reference = doc(configurationsCollection, ConfigurationDocument.AiIntegration);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            throw new Error("AI Integration configuration not found");
        }
        return snapshot.data() as AiIntegration;
    }
    static async setAiIntegration(data: AiIntegration) {
        const reference = doc(configurationsCollection, ConfigurationDocument.AiIntegration);
        await setDoc(reference, data);
    }
    static async getAlgolia() {
        const reference = doc(configurationsCollection, "algolia");
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            throw new Error("Algolia configuration not found");
        }
        return snapshot.data() as AlgoliaConfiguration;
    }
    static async getMobileServiceFee() {
        const reference = doc(configurationsCollection, ConfigurationDocument.MobileServiceFee);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            throw new Error("Mobile Service Fee configuration not found");
        }
        return snapshot.data() as MobileServiceFeeConfiguration;
    }
    private static listenAttributeChanges(id: string, attributeName: string, callback: () => void) {
        const reference = doc(configurationsCollection, id);
        return onSnapshot(reference, snapshot => {
            const data = snapshot.data();
            if (data && data[attributeName] !== undefined) {
                callback();
            }
        });
    }
    static listenAlgoliaCustomerChanges(callback: () => void) {
        return ConfigurationRepository.listenAttributeChanges("algolia", "customers_index_refresh_key", callback);
    }
    static listenAlgoliaGroomerChanges(callback: () => void) {
        return ConfigurationRepository.listenAttributeChanges("algolia", "groomers_index_refresh_key", callback);
    }
}