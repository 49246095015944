import { recurrencesCollection, getNonEmptyData } from "@marathon/client-side/database";
import {
    doc, getDoc, getDocs, addDoc, updateDoc, query, where,
    DocumentSnapshot, UpdateData, onSnapshot
} from "firebase/firestore";
import { AppointmentRepository } from "./AppointmentRepository";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { AppointmentStatus, PaymentStatus } from "@marathon/common/entities/Appointment";
import { Recurrence, RecurrenceData } from "@marathon/common/entities/Recurrence";
import { Occurrence } from "@marathon/common/entities/Occurrence";
import { AppointmentData } from "@marathon/common/entities/Appointment";
import { ONLINE_BOOKING_ORIGIN } from "@marathon/common/constants";
import { TimeZone, getDateWithMergedTime } from "@marathon/common/timeZoneHelper";
import { CustomerRepository } from "./CustomerRepository";
import { AppointmentInput } from "../entities/AppointmentInput";
import { getFutureOccurrences, getOccurrenceFromIndex, getOccurrencesForDate } from "@marathon/common/rruleHelper";
import { SecondaryAddressRepository } from "./SecondaryAddressRepository";
import LocalDate from "@marathon/common/LocalDate";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";
import { isFirstHourService } from "@marathon/common/timeFormatHelper";
import { PetRepository } from "./PetRepository";
import { Groomer } from "@marathon/common/entities/Groomer";
import { Service } from "@marathon/common/entities/Service";
import { DriveTime } from "@marathon/common/entities/DriveTime";

const mapEntity = function (snapshot: DocumentSnapshot<RecurrenceData>) {
    return new Recurrence(snapshot.id, getNonEmptyData(snapshot));
};

const updateAudit = (data: UpdateData<RecurrenceData>) => {
    if (!data) return;
    data.updated_by = DeviceStorageCache.getCurrentUserName() || ONLINE_BOOKING_ORIGIN;
    data.updated_at = new Date();
};

const searchInternal = async (customerId?: string, groomerId?: string, timeZone?: TimeZone) => {
    let customQuery = query(
        recurrencesCollection,
        where("status", "==", AppointmentStatus.scheduled)
    );

    if (customerId)
        customQuery = query(customQuery, where("customer.id", "==", customerId));

    if (groomerId)
        customQuery = query(customQuery, where("groomer.id", "==", groomerId));

    if (timeZone)
        customQuery = query(customQuery, where("time_zone", "==", timeZone));

    const snapshot = await getDocs(customQuery);
    return snapshot.docs.map(x => mapEntity(x));
};

const addExceptionInternal = async (recurrence: Recurrence, exceptionIndex: number) => {
    const exceptions = recurrence.exceptions
        ? [...recurrence.exceptions, exceptionIndex]
        : [exceptionIndex];
    const reference = doc(recurrencesCollection, recurrence.id);
    await updateDoc(reference, { exceptions });
};

interface RecurrenceUpdateInput {
    id: string,
    occurrenceIndex: number,
    originalRecurrence: Recurrence,
    appointmentInput: AppointmentInput,
    groomer: Groomer,
    services: Service[]
}

export class RecurrenceRepository {
    static async getById(id: string) {
        const reference = doc(recurrencesCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
    static async create(data: RecurrenceData) {
        if (await CallableFunctions.public.isNewCustomer(data.customer.id)) {
            data.is_new_recurrent_customer = true;
        }
        if (await CallableFunctions.public.isFirstAppointment(data.groomer.id, data.start_time, data.time_zone) && isFirstHourService(data.start_time, data.time_zone)) {
            data.show_exact_time = true;
        }
        const docRef = await addDoc(recurrencesCollection, data);
        return new Recurrence(docRef.id, data);
    }
    static async update(id: string, data: UpdateData<RecurrenceData>) {
        updateAudit(data);
        const reference = doc(recurrencesCollection, id);
        await updateDoc(reference, data);
    }
    static async cancel(id: string, reason: string, notifyCustomer?: boolean, collectedFee?: number) {
        const data: UpdateData<RecurrenceData> = {
            status: AppointmentStatus.cancelled,
            cancellation: {
                reason,
                customer_notified: notifyCustomer || undefined
            }
        };

        updateAudit(data);
        const reference = doc(recurrencesCollection, id);
        await updateDoc(reference, data);

        await this.materializeNextCancelledOccurrence(id, reason, collectedFee);
    }
    static async unconfirmOccurrence(occurrence: Occurrence) {
        const toUpdate = {
            status: AppointmentStatus.unconfirmed,
            unconfirmed: {
                by: DeviceStorageCache.getNonEmptyCurrentUserName(),
                at: new Date()
            }
        };

        return await RecurrenceRepository.createExceptionFromOccurrence(occurrence, toUpdate);
    }
    static async cancelOccurrence(occurrence: Occurrence, reason: string, notifyCustomer: boolean, collectedFee?: number) {
        const toUpdate = {
            status: AppointmentStatus.cancelled,
            cancellation: {
                reason,
                customer_notified: notifyCustomer || undefined,
                collected_fee: collectedFee
            },
            payment_status: collectedFee ? PaymentStatus.paid : undefined
        };

        return await RecurrenceRepository.createExceptionFromOccurrence(occurrence, toUpdate);
    }
    static async createException(id: string, occurrenceIndex: number, toUpdate: Partial<AppointmentData>) {
        const recurrence = await RecurrenceRepository.getById(id);
        if (!recurrence)
            throw new Error(`RecurrenceId ${id} not valid`);

        const occurrence = getOccurrenceFromIndex(recurrence, occurrenceIndex);

        recurrence.validateExceptionIndex(occurrence);
        const newAppointment = await AppointmentRepository.create(occurrence.createAppointmentData(toUpdate));
        await addExceptionInternal(recurrence, occurrenceIndex);
        return newAppointment;
    }
    static async createExceptionFromOccurrence(occurrence: Occurrence, toUpdate: Partial<AppointmentData>) {
        const recurrence = await RecurrenceRepository.getById(occurrence.id);
        if (!recurrence)
            throw new Error(`RecurrenceId ${occurrence.id} not valid`);

        toUpdate.updated_at = new Date();
        toUpdate.updated_by = DeviceStorageCache.getNonEmptyCurrentUserName();

        recurrence.validateExceptionIndex(occurrence);
        const newAppointment = await AppointmentRepository.create(occurrence.createAppointmentData(toUpdate));
        await addExceptionInternal(recurrence, occurrence.occurrenceIndex);
        return newAppointment;
    }
    static async createExceptionFromOnlineBooking(customerOccurrence: Occurrence, toUpdate: Partial<AppointmentData>, customerRecurrences: Recurrence[]) {
        const customerRecurrence = customerRecurrences.find(x => x.id === customerOccurrence.id);
        if (!customerRecurrence)
            throw new Error(`RecurrenceId ${customerOccurrence.id} not valid`);

        const recurrence: Recurrence = new Recurrence(customerRecurrence.id, customerRecurrence);
        const occurrence: Occurrence = new Occurrence(customerOccurrence.id, customerOccurrence.occurrenceIndex, customerOccurrence);

        toUpdate.updated_at = new Date();
        toUpdate.updated_by = DeviceStorageCache.getCurrentUserName() || ONLINE_BOOKING_ORIGIN;

        recurrence.validateExceptionIndex(occurrence);
        const newAppointment = await AppointmentRepository.create(occurrence.createAppointmentData(toUpdate));
        await addExceptionInternal(recurrence, occurrence.occurrenceIndex);
        return newAppointment;
    }
    static async cancelOccurrenceFromOnlineBooking(occurrence: Occurrence, reason: string, customerRecurrences: Recurrence[]) {
        const toUpdate = {
            status: AppointmentStatus.cancelled,
            cancellation: { reason }
        };
        return await RecurrenceRepository.createExceptionFromOnlineBooking(occurrence, toUpdate, customerRecurrences);
    }
    static async materializeNextCancelledOccurrence(id: string, cancellationReason: string, collectedFee?: number) {
        const recurrence = await RecurrenceRepository.getById(id);
        if (!recurrence)
            throw new Error(`RecurrenceId ${id} not valid`);

        const nextOccurrence = getFutureOccurrences(recurrence, 1).at(0);
        if (!nextOccurrence)
            throw new Error(`Next occurrence not found for recurrence ${id}`);

        const data = {
            ...nextOccurrence.toAppointmentData(),
            status: AppointmentStatus.cancelled,
            cancellation: { reason: cancellationReason, collected_fee: collectedFee },
            payment_status: collectedFee ? PaymentStatus.paid : undefined
        };

        await RecurrenceRepository.createExceptionFromOccurrence(nextOccurrence, data);
    }
    static async search() {
        return await searchInternal();
    }
    static async searchByHub(hubId: string) {
        const snapshot = await getDocs(
            query(recurrencesCollection,
                where("status", "==", AppointmentStatus.scheduled),
                where("groomer.hub_id", "==", hubId)
            )
        );
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchForTimeZone(timeZone: TimeZone) {
        return await searchInternal(undefined, undefined, timeZone);
    }
    static async searchForCustomer(customerId: string) {
        return await searchInternal(customerId, undefined, undefined);
    }
    static async searchForGroomer(groomerId: string) {
        return await searchInternal(undefined, groomerId, undefined);
    }
    static async searchOccurrencesForDayAndGroomer(date: LocalDate, groomerId: string) {
        const recurrences = await this.searchForGroomer(groomerId);
        return getOccurrencesForDate(recurrences, date);
    }
    static async searchFromOnlineBooking() {
        return await CallableFunctions.public.getCustomerRecurrences();
    }
    static listenFromCalendar(timeZone: TimeZone | undefined, callback: (recurrences: Recurrence[]) => void): () => void {
        const filters = [
            getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled)
        ];

        if (timeZone) {
            filters.push(
                getFilter("time_zone", FilterOperator.equal, timeZone)
            );
        }

        let customQuery = query(recurrencesCollection);
        filters.forEach(filter => {
            customQuery = query(customQuery, where(filter.field, filter.operator, filter.value));
        });
        return onSnapshot(customQuery, snapshot => {
            callback(snapshot.docs.map(x => mapEntity(x)));
        });
    }
    static listenChangesForCustomer(customerId: string, callback: (recurrences: Recurrence[]) => void): () => void {
        const customQuery = query(recurrencesCollection,
            where("status", "==", AppointmentStatus.scheduled),
            where("customer.id", "==", customerId)
        );
        return onSnapshot(customQuery, snapshot => {
            callback(snapshot.docs.map(x => mapEntity(x)));
        });
    }
    static listenChangesForGroomer(groomerId: string, callback: (recurrences: Recurrence[]) => void): () => void {
        const customQuery = query(recurrencesCollection,
            where("status", "==", AppointmentStatus.scheduled),
            where("groomer.id", "==", groomerId)
        );
        return onSnapshot(customQuery, snapshot => {
            callback(snapshot.docs.map(x => mapEntity(x)));
        });
    }
    static populateOccurrenceDriveTime(occurrence: Occurrence, driveTimes: DriveTime[]) {
        const driveTime = driveTimes.find(x => x.parentId === occurrence.id && x.id === occurrence.occurrenceIndex.toString());
        if (driveTime) {
            occurrence.drive_time = driveTime;
        }
    }
    static async updateRecurrence(recurrenceUpdateInput: RecurrenceUpdateInput) {
        const { id, originalRecurrence, appointmentInput, groomer, services } = recurrenceUpdateInput;

        if (!appointmentInput.customerId)
            throw new Error("Customer selection is required at this point");

        const customer = await CustomerRepository.getById(appointmentInput.customerId);
        if (!customer)
            throw new Error(`Customer ${appointmentInput.customerId} not found`);

        const secondaryAddress = appointmentInput.secondaryAddressId
            ? await SecondaryAddressRepository.getById(customer.id, appointmentInput.secondaryAddressId)
            : undefined;

        const pets = await PetRepository.search(customer.id);

        const toUpdate = AppointmentRepository.getAppointmentToUpdate(appointmentInput, groomer, customer, services, pets, secondaryAddress);

        if (!toUpdate.start_time || !toUpdate.end_time)
            throw new Error("StartTime and EndTime fields are required at this point");

        toUpdate.start_time = getDateWithMergedTime(originalRecurrence.start_time, toUpdate.start_time, groomer.time_zone);
        toUpdate.end_time = getDateWithMergedTime(originalRecurrence.end_time, toUpdate.end_time, groomer.time_zone);
        await RecurrenceRepository.update(id, toUpdate);
    }
    static async recreateRecurrence(updateInput: RecurrenceUpdateInput) {
        const { id, appointmentInput, groomer, services } = updateInput;

        if (!appointmentInput.customerId)
            throw new Error("Customer selection is required at this point");

        const customer = await CustomerRepository.getById(appointmentInput.customerId);
        if (!customer)
            throw new Error(`Customer ${appointmentInput.customerId} not found`);

        const secondaryAddress = appointmentInput.secondaryAddressId
            ? await SecondaryAddressRepository.getById(customer.id, appointmentInput.secondaryAddressId)
            : undefined;

        const pets = await PetRepository.search(customer.id);

        const newAppointmentData = AppointmentRepository.getAppointmentToCreate(appointmentInput, groomer, customer, services, pets, secondaryAddress, true);
        await RecurrenceRepository.create(newAppointmentData);

        const reference = doc(recurrencesCollection, id);
        await updateDoc(reference, { status: AppointmentStatus.cancelled });
    }
    static async handleUpdateWholeSeries(recurrenceUpdateInput: RecurrenceUpdateInput) {
        const { occurrenceIndex, originalRecurrence, appointmentInput } = recurrenceUpdateInput;

        const { startTime, frequency } = appointmentInput;
        if (!frequency)
            throw new Error("Frequency is required at this point");

        const sameDay = originalRecurrence.areSameDate(occurrenceIndex, startTime);
        const sameFrecuency = originalRecurrence.getFrequency().interval === frequency.interval;

        if (sameDay && sameFrecuency) {
            await RecurrenceRepository.updateRecurrence(recurrenceUpdateInput);
        } else {
            await RecurrenceRepository.recreateRecurrence(recurrenceUpdateInput);
        }
    }
}
