import { addDoc, doc, updateDoc, query, where, getDoc, getDocs, DocumentSnapshot, orderBy, deleteField } from "firebase/firestore";
import { Pet, PetData, PetStatus } from "@marathon/common/entities/Pet";
import { customerPetsCollection, petsCollectionGroup, getNonEmptyData, getParentId } from "@marathon/client-side/database";
import LocalDate from "@marathon/common/LocalDate";
import { calculatePetAge, getBirthday } from "@marathon/common/timeHelper";
import { PetInput, serviceInputToPetService } from "../entities/PetInput";
import { Breed } from "@marathon/common/entities/Breed";
import { PricingPreset } from "@marathon/common/entities/PricingPreset";

const mapEntity = function (snapshot: DocumentSnapshot<PetData>) {
    return new Pet(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class PetRepository {
    static async create(customerId: string, input: PetInput) {
        const collection = customerPetsCollection(customerId);
        const data = getPetDataForCreate(input, customerId);
        const newDocument = await addDoc(collection, data);
        return new Pet(newDocument.id, customerId, data);
    }
    static async search(customerId: string, onlyActive = true) {
        const collection = customerPetsCollection(customerId);
        let customQuery = query(collection);
        if (onlyActive)
            customQuery = query(customQuery, where("status", "==", PetStatus.active));
        const snapshot = await getDocs(customQuery);
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchDeceased(fromDate: LocalDate, toDate: LocalDate) {
        const collection = petsCollectionGroup();
        const customQuery = query(collection,
            where("is_deceased", "==", true),
            where("deceased_at", ">=", fromDate.toDayStart()),
            where("deceased_at", "<=", toDate.toDayEnd()),
            orderBy("deceased_at", "desc")
        );
        const snapshot = await getDocs(customQuery);
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async updateNotes(customerId: string, petId: string, notes: string) {
        const collection = customerPetsCollection(customerId);
        const reference = doc(collection, petId);
        await updateDoc(reference, { notes });
    }
    static async updateFromInput(customerId: string, petId: string, input: PetInput) {
        if (!input.services || input.services.length === 0)
            throw new Error("Invalid pet services configuration");

        const collection = customerPetsCollection(customerId);
        const reference = doc(collection, petId);
        const original = await getDoc(reference);
        if (!original.exists())
            throw new Error("Pet not found");

        const data = getPetDataForUpdate(input, original.data());
        await updateDoc(reference, { ...data });
    }
    static async remove(customerId: string, petId: string) {
        const collection = customerPetsCollection(customerId);
        const reference = doc(collection, petId);
        await updateDoc(reference, { status: PetStatus.deleted });
    }
    static async markAsDeceased(customerId: string, petId: string) {
        const collection = customerPetsCollection(customerId);
        const reference = doc(collection, petId);
        await updateDoc(reference, {
            status: PetStatus.deleted,
            is_deceased: true,
            deceased_at: new Date()
        });
    }
    static getServices(breed: Breed, weight: number, pricingPreset: PricingPreset) {
        const servicesIds = breed.getServiceIdsByWeight(weight);
        if (!servicesIds)
            throw new Error("Is not possible to determine pet's services configuration");

        return servicesIds.map(x => PetRepository.toServiceInput(x, pricingPreset));
    }
    static toServiceInput(serviceId: string, pricingPreset: PricingPreset) {
        const servicePrice = pricingPreset.services.find(x => x.service_id === serviceId);
        if (!servicePrice)
            throw new Error(`Service ${serviceId} not valid for preset ${pricingPreset.id}`);

        return {
            id: serviceId,
            price: servicePrice.price
        };
    }
    static getInstanceFromInput(petId: string, customerId: string, input: PetInput) {
        const data: PetData = {
            name: input.name?.trim() ?? "",
            breed_id: input.breed_id,
            weight: Math.round(input.weight ?? 0),
            notes: input.notes ?? "",
            gender: input.gender ?? "",
            services: input.services?.map(x => serviceInputToPetService(x)) ?? [],
            created_at: new Date(),
            status: PetStatus.active,
            customer_id: customerId
        };

        if (!!input.age && !isNaN(input.age) && input.age > 0) {
            data.age_date = getBirthday(input.age);
        }

        return new Pet(petId, customerId, data);
    }
    static async updateProfilePhotoUrl(customerId: string, petId: string, profilePhotoUrl: string) {
        const collection = customerPetsCollection(customerId);
        const reference = doc(collection, petId);
        await updateDoc(reference, { profile_photo_url: profilePhotoUrl });
    }
    static async removeProfilePhotoUrl(customerId: string, petId: string) {
        const collection = customerPetsCollection(customerId);
        const reference = doc(collection, petId);
        await updateDoc(reference, { profile_photo_url: deleteField() });
    }
    static toInput(pet: Pet): PetInput {
        return ({
            id: pet.id,
            name: pet.name,
            breed_id: pet.breed_id,
            weight: pet.weight,
            age: pet.age_date ? calculatePetAge(pet.age_date) : 0,
            gender: pet.gender,
            notes: pet.notes,
            price: pet.price,
            services: pet.services,
            profile_photo_url: pet.profile_photo_url
        });
    }
}

const getPetDataForCreate = (input: PetInput, customerId: string): PetData => {
    if (!input.age || isNaN(input.age) || input.age < 0)
        throw new Error("Age is required at this point");

    if (!input.services || input.services.length === 0)
        throw new Error("Invalid pet services configuration");

    const data: PetData = {
        name: input.name?.trim() ?? "",
        breed_id: input.breed_id,
        weight: Math.round(input.weight ?? 0),
        notes: input.notes ?? "",
        gender: input.gender ?? "",
        services: input.services.map(x => serviceInputToPetService(x)),
        customer_id: customerId,
        age_date: getBirthday(input.age),
        created_at: new Date(),
        status: PetStatus.active
    };

    return data;
};

const getPetDataForUpdate = (input: PetInput, original: PetData): Partial<PetData> => {
    const data: Partial<PetData> = {
        name: input.name?.trim() ?? "",
        breed_id: input.breed_id,
        weight: Math.round(input.weight ?? 0),
        notes: input.notes ?? "",
        gender: input.gender ?? "",
        services: input.services?.map(x => serviceInputToPetService(x)) ?? []
    };

    const shouldSetAge = !!input.age && (!original.age_date || calculatePetAge(original.age_date) !== input.age);
    if (shouldSetAge) {
        if (!input.age || isNaN(input.age) || input.age < 0)
            throw new Error("Age is required at this point");

        data.age_date = getBirthday(input.age);
    }

    return data;
};