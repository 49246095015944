import { groomersCollection, getNonEmptyData } from "@marathon/client-side/database";
import { Groomer, GroomerData, GroomerStatus } from "@marathon/common/entities/Groomer";
import { doc, getDoc, getDocs, addDoc, updateDoc, query, where, orderBy, DocumentSnapshot, deleteField, QueryConstraint, onSnapshot, UpdateData } from "firebase/firestore";
import { AppointmentRepository } from "./AppointmentRepository";
import { RecurrenceRepository } from "./RecurrenceRepository";
import { getFutureOccurrences, getOccurrenceFromIndex } from "@marathon/common/rruleHelper";
import { AppointmentOrOccurrence } from "@marathon/common/entities/Occurrence";
import { MessageRepository } from "./MessageRepository";
import { DailyAnnouncementData } from "@marathon/common/entities/DailyAnnouncement";
import { DailyAnnouncementRepository } from "./DailyAnnouncementRepository";
import { GroomerScheduleInput } from "../entities/GroomerScheduleInput";
import { normalizePhone } from "@marathon/common/normalizationHelper";
import { MessageParentType } from "@marathon/common/entities/Message";
import LocalDate from "@marathon/common/LocalDate";
import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import { Appointment, AppointmentData, AppointmentStatus } from "@marathon/common/entities/Appointment";

const mapEntity = function (snapshot: DocumentSnapshot<GroomerData>) {
    return new Groomer(snapshot.id, getNonEmptyData(snapshot));
};

const updateData = (data: GroomerData, isUpdate = false) => {
    data.firstname = data.firstname?.trim();
    data.lastname = data.lastname?.trim();
    data.phone = data.phone ? normalizePhone(data.phone) : "";

    if (isUpdate) {
        data.updated_at = new Date();
    }
    else {
        data.created_at = new Date();
    }
};

export class GroomerRepository {
    static async getById(id: string) {
        const reference = doc(groomersCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
    static async getByUid(uid: string) {
        const customQuery = query(groomersCollection, where("uid", "==", uid));
        const snapshot = await getDocs(customQuery);
        if (snapshot.empty) {
            return null;
        }
        return mapEntity(snapshot.docs[0]);
    }
    static async search() {
        const snapshot = await getDocs(
            query(groomersCollection,
                where("status", "==", GroomerStatus.active),
                orderBy("hub_id", "asc"),
                orderBy("firstname", "asc")
            )
        );
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchAll() {
        const snapshot = await getDocs(
            query(groomersCollection,
                where("status", "in", [GroomerStatus.active, GroomerStatus.inactive]),
                orderBy("hub_id", "asc"),
                orderBy("firstname", "asc")
            )
        );
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchAvailableOnline() {
        const groomers = await this.search();
        return groomers.filter(g => g.available_online);
    }
    static async create(groomerData: GroomerData) {
        updateData(groomerData);
        return await addDoc(groomersCollection, groomerData);
    }
    static async update(id: string, groomerData: GroomerData) {
        updateData(groomerData, true);
        const reference = doc(groomersCollection, id);
        await updateDoc(reference, {
            ...groomerData,
            start_date: groomerData.start_date ?? deleteField(),
            end_date: groomerData.end_date ?? deleteField(),
            allow_manual_booking: groomerData.allow_manual_booking ?? deleteField(),
        });
    }
    static async remove(id: string) {
        const reference = doc(groomersCollection, id);
        await updateDoc(reference, { status: GroomerStatus.deleted });
    }
    static async sendDailyAnnouncement(groomer: Groomer) {
        const sendDate = LocalDate.forContextTimeZone(new Date(), groomer.time_zone);

        const getMessageContent = (appointment: AppointmentOrOccurrence) => {
            let message = `Hi ${appointment.customer.name.split(" ")[0]}! This is Barkbus Mobile Grooming. `;
            message += appointment.show_exact_time
                ? `We look forward to your ${Appointment.getArrivalTimeWithLabel(appointment, { includePreposition: false })} appointment. See you soon!`
                : `We look forward to your appointment later today with a ${Appointment.getArrivalTimeWithLabel(appointment, { includePreposition: false })} arrival window. We will text you when we’re on the way!`;
            return message;
        };

        const existingAnnouncements = await DailyAnnouncementRepository.search(sendDate, groomer.id);
        if (existingAnnouncements.length)
            throw new Error("An announcement was already sent for the day");

        const allAppointments = await this.searchScheduledAppointmentsAndOccurrencesForDate(groomer.id, sendDate);
        const announcement: DailyAnnouncementData = {
            sent_date: sendDate.toDayStart(),
            time_zone: groomer.time_zone,
            messages: []
        };

        for (const appointment of allAppointments) {
            const message = await MessageRepository.createSms({
                collectionType: MessageParentType.Customers,
                parentId: appointment.customer.id,
                content: getMessageContent(appointment),
                phone: appointment.customer.phone
            });

            announcement.messages.push({
                customerId: appointment.customer.id,
                messageId: message.id
            });
        }

        await DailyAnnouncementRepository.create(groomer.id, announcement);
    }
    static getScheduleFromInput = (scheduleInput: GroomerScheduleInput[]) => {
        return scheduleInput.filter(x => x.active).map(x => {
            return {
                day: x.day, from: x.from, to: x.to, cut_off: x.cut_off
            };
        });
    };
    private static listenByLastMessages(queries: QueryConstraint[], callback: (data: Groomer[]) => void) {
        const constraints = [
            where("status", "==", GroomerStatus.active),
            ...queries
        ];

        const groomerQuery = query(groomersCollection, ...constraints);
        return onSnapshot(groomerQuery, (querySnapshot) => {
            const groomers: Groomer[] = [];
            querySnapshot.forEach(doc => {
                groomers.push(mapEntity(doc));
            });
            groomers.sort((a, b) =>
                (b.last_message_at?.getTime() || 0) -
                (a.last_message_at?.getTime() || 0)
            );
            callback(groomers);
        });
    }
    static listenAll(callback: (data: Groomer[]) => void) {
        const constraints = [
            where("status", "in", [GroomerStatus.active, GroomerStatus.inactive])
        ];
        const groomerQuery = query(groomersCollection, ...constraints);
        return onSnapshot(groomerQuery, (querySnapshot) => {
            const groomers: Groomer[] = [];
            querySnapshot.forEach(doc => {
                groomers.push(mapEntity(doc));
            });
            callback(groomers);
        });
    }
    static listenByLastMessagesOpen(callback: (data: Groomer[]) => void) {
        const constraints = [
            where("open_conversation", "==", true)
        ];
        return GroomerRepository.listenByLastMessages(constraints, callback);
    }
    static async toggleOpenConversation(id: string, currentValue: boolean) {
        const reference = doc(groomersCollection, id);
        const updateData: UpdateData<Groomer> = {
            open_conversation: !currentValue,
            updated_by: DeviceStorageCache.getCurrentUserName() ?? undefined
        };
        await updateDoc(reference, updateData);
    }
    static async markNewMessage(id: string, status = false) {
        const reference = doc(groomersCollection, id);
        await updateDoc(reference, { new_message: status });
    }
    static listenChanges(id: string, callback: (data: Groomer) => void) {
        const reference = doc(groomersCollection, id);
        return onSnapshot(reference, snapshot => {
            if (!snapshot.exists())
                return;
            callback(mapEntity(snapshot));
        });
    }
    static async createNote(id: string, note: string, callback: (notes: string) => void) {
        const reference = doc(groomersCollection, id);
        await updateDoc(reference, { notes: note });
        const snapshot = await getDoc(reference);
        if (!snapshot.exists())
            return;
        callback(mapEntity(snapshot).notes ?? "");
    }
    static async searchUpcomingAppointmentOrOccurrence(groomerId: string) {
        const appointments = await AppointmentRepository.searchUpcomingByGroomer(groomerId, new Date, 1);
        const recurrences = await RecurrenceRepository.searchForGroomer(groomerId);
        const futureOccurrences = recurrences.map(x => getFutureOccurrences(x, 1)).flat();
        const all = [
            ...appointments,
            ...futureOccurrences
        ];
        if (!all || all.length === 0)
            return null;

        return all.sortByFieldAscending(x => x.start_time)[0];
    }
    static async searchScheduledAppointmentsAndOccurrencesForDate(groomerId: string, localDate: LocalDate) {
        const appointments = await AppointmentRepository.searchForDayAndGroomer(localDate, localDate, groomerId);
        const occurrences = await RecurrenceRepository.searchOccurrencesForDayAndGroomer(localDate, groomerId);
        const allAppointments: AppointmentOrOccurrence[] = [];
        allAppointments.push(...appointments);
        allAppointments.push(...occurrences);
        const filteredList = allAppointments.filter(x => x.status === AppointmentStatus.scheduled);
        filteredList.sortByFieldAscending(x => x.start_time);
        return filteredList;
    }
    static async notifyAppointmentCancellationFromId(id: string, occurrenceIndex?: number) {
        if (occurrenceIndex !== undefined) {
            const recurrence = await RecurrenceRepository.getById(id);
            if (!recurrence) throw new Error(`Recurrence ${id} not found`);
            const occurrence = getOccurrenceFromIndex(recurrence, occurrenceIndex);
            await this.notifyAppointmentCancellation(occurrence);
        }
        else {
            const appointment = await AppointmentRepository.getById(id);
            if (!appointment) throw new Error(`Appointment ${id} not found`);
            await this.notifyAppointmentCancellation(appointment);
        }
    }
    static async notifyAppointmentCancellation(appointment: AppointmentData) {
        if (!Appointment.isToday(appointment.start_time, appointment.time_zone))
            return;

        const groomer = await this.getById(appointment.groomer.id);
        if (!groomer)
            throw new Error(`Groomer ${appointment.groomer.id} not found`);

        const message = `Notification - An appointment was canceled today:
          Customer: ${appointment.customer.name}\n Number of dogs: ${appointment.selected_pets.length > 1
                ? `${appointment.selected_pets.length} dogs`
                : "1 dog"}
          Arrival window:  ${Appointment.getArrivalTimeWithLabel(appointment)}`;

        await MessageRepository.createSms({
            collectionType: MessageParentType.Groomers,
            parentId: groomer.id,
            content: message,
            phone: groomer.phone
        });
    }
    static async notifyAppointmentChange(appointmentId: string) {
        const appointment = await AppointmentRepository.getById(appointmentId);
        if (!appointment)
            throw new Error(`Appointment ${appointmentId} not found`);

        const groomer = await this.getById(appointment.groomer.id);
        if (!groomer)
            throw new Error(`Groomer ${appointment.groomer.id} not found`);

        const message = `Notification - An appointment for today has been modified:
         Customer:${appointment.customer.name}
         Number of dogs: ${appointment.selected_pets.length > 1
                ? `${appointment.selected_pets.length} dogs`
                : "1 dog"}
         Arrival window:  ${Appointment.getArrivalTimeWithLabel(appointment)}`;

        await MessageRepository.createSms({
            collectionType: MessageParentType.Groomers,
            parentId: groomer.id,
            content: message,
            phone: groomer.phone
        });
    }
}
