import { customerSecondaryAddressesCollection, getNonEmptyData, getParentId, secondaryAddressesCollectionGroup } from "@marathon/client-side/database";
import { DocumentSnapshot, addDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { SecondaryAddress, SecondaryAddressData, SecondaryAddressStatus } from "@marathon/common/entities/SecondaryAddress";

const mapEntity = function (snapshot: DocumentSnapshot<SecondaryAddressData>) {
    return new SecondaryAddress(snapshot.id, getParentId(snapshot), getNonEmptyData(snapshot));
};

export class SecondaryAddressRepository {
    static async getById(customerId: string, id: string) {
        const collection = customerSecondaryAddressesCollection(customerId);
        const reference = doc(collection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            throw new Error(`Secondary address ${id} not found for customer ${customerId}`);
        }
        return mapEntity(snapshot);
    }
    static async search(customerId: string) {
        const collection = customerSecondaryAddressesCollection(customerId);
        const customQuery = query(collection,
            where("status", "!=", SecondaryAddressStatus.hidden)
        );
        const snapshot = await getDocs(customQuery);
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async searchByHub(hubId: string) {
        const collection = secondaryAddressesCollectionGroup();
        const snapshot = await getDocs(query(collection));
        const addresses = snapshot.docs.map(x => mapEntity(x));
        return addresses.filter(x =>
            x.status !== SecondaryAddressStatus.hidden &&
            x.drive_time?.hub_id === hubId
        );
    }
    static async searchAll() {
        const collection = secondaryAddressesCollectionGroup();
        const snapshot = await getDocs(query(collection));
        const addresses = snapshot.docs.map(x => mapEntity(x));
        return addresses.filter(x =>
            x.status !== SecondaryAddressStatus.hidden
        );
    }
    static async create(customerId: string, values: SecondaryAddressInput) {
        const data = this.fromInput(values);
        const collection = customerSecondaryAddressesCollection(customerId);
        const newDocument = await addDoc(collection, data);
        return new SecondaryAddress(newDocument.id, customerId, data);
    }
    static async update(customerId: string, secondaryAddressId: string, values: SecondaryAddressInput) {
        const data = {
            address1: values.address1,
            address2: values.address2,
            parking_notes: values.parking_notes,
            state: values.state,
            city: values.city,
            country: values.country,
            zip: values.zip,
            area: values.area,
            street_name: values.street_name,
            place_id: values.place_id,
            lat: values.lat,
            lng: values.lng,
            drive_time: values.drive_time,
            alias: values.alias,
            created_at: new Date(),
            status: values.status
        } as SecondaryAddressData;
        const collection = customerSecondaryAddressesCollection(customerId);
        const reference = doc(collection, secondaryAddressId);
        await updateDoc(reference, { ...data });
    }
    static async remove(customerId: string, secondaryContactId: string) {
        const collection = customerSecondaryAddressesCollection(customerId);
        const data = {
            status: SecondaryAddressStatus.hidden,
        } as SecondaryAddressData;
        const reference = doc(collection, secondaryContactId);
        await updateDoc(reference, { ...data });
    }
    static fromInput(values: SecondaryAddressInput) {
        return {
            address1: values.address1,
            address2: values.address2,
            parking_notes: values.parking_notes,
            state: values.state,
            city: values.city,
            country: values.country,
            zip: values.zip,
            area: values.area,
            street_name: values.street_name,
            place_id: values.place_id,
            lat: values.lat,
            lng: values.lng,
            drive_time: values.drive_time,
            alias: values.alias,
            created_at: new Date(),
            status: values.status
        } as SecondaryAddressData;
    }
    static toInput(secondaryAddress: SecondaryAddress): SecondaryAddressInput {
        return ({
            id: secondaryAddress.id,
            address1: secondaryAddress.address1,
            address2: secondaryAddress.address2,
            parking_notes: secondaryAddress.parking_notes,
            state: secondaryAddress.state,
            city: secondaryAddress.city,
            country: secondaryAddress.country,
            zip: secondaryAddress.zip,
            area: secondaryAddress.area,
            street_name: secondaryAddress.street_name,
            place_id: secondaryAddress.place_id,
            lat: secondaryAddress.lat,
            lng: secondaryAddress.lng,
            drive_time: secondaryAddress.drive_time,
            alias: secondaryAddress.alias,
            status: secondaryAddress.status
        });
    }
}

export interface SecondaryAddressInput {
    id?: string,
    alias?: string,
    address1?: string,
    address2?: string,
    parking_notes?: string,
    state?: string,
    city?: string,
    country?: string,
    zip?: string,
    area?: string,
    street_name?: string,
    place_id?: string,
    lat?: number,
    lng?: number,
    drive_time?: {
        duration_text: string,
        duration_value: number,
        hub_id: string
    }
    status?: SecondaryAddressStatus,
}
