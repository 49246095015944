import { DateTime } from "luxon";
import { TimeZone } from "@marathon/common/timeZoneHelper";
import { formatTime24 } from "@marathon/common/timeFormatHelper";
import { GroomerException } from "./GroomerException";
import LocalDate from "../LocalDate";
import { VanException } from "./VanException";
import { Van } from "./Van";
import { DayOfWeek, zeroBasedDaysOfWeek } from "../constants";
import { shouldBeUnreachable } from "../typesHelper";

interface GroomerScheduleData {
    day: DayOfWeek,
    from: string,
    to: string,
    cut_off?: boolean,
}

interface GroomerRestrictionData {
    service_ids: string[]
}

enum Gender {
    male = "male",
    female = "female",
    nonBinary = "non-binary"
}

interface AssignedVan { van?: Van, exception_id?: string }

interface GroomerData {
    firstname: string,
    lastname: string,
    phone: string,
    email: string,
    hub_id: string,
    photo: string,
    notes?: string,
    role: string,
    uid: string,
    air_call?: {
        contact_id: number,
        phone_id: number
    },
    created_at: Date,
    updated_by?: string,
    updated_at?: Date,
    open_conversation: boolean,
    schedule: GroomerScheduleData[],
    status: GroomerStatus,
    available_online: boolean,
    only_performs_bath?: boolean,
    start_date?: Date,
    end_date?: Date,
    restrictions?: GroomerRestrictionData
    time_zone: TimeZone
    new_message?: boolean
    last_message_at?: Date,
    gender?: Gender,
    allow_manual_booking?: true,
}

interface Groomer extends GroomerData {
    id: string
}

interface GroomerSummary {
    id: string,
    firstname: string,
    lastname: string,
    fullname: string,
    obfuscatedName: string,
    hub_id: string,
    phone: string,
    email: string
}

class Groomer {
    constructor(id: string, data: GroomerData) {
        this.id = id;
        Object.assign(this, data);
    }
    fullname(): string {
        return this.firstname + " " + this.lastname;
    }
    obfuscatedName(): string {
        return this.firstname + " " + this.lastname.charAt(0);
    }
    toData(): GroomerData {
        const { id, ...data } = this;
        return data;
    }
    toSummary(): GroomerSummary {
        return {
            id: this.id,
            firstname: this.firstname,
            lastname: this.lastname,
            fullname: this.fullname(),
            obfuscatedName: this.obfuscatedName(),
            hub_id: this.hub_id,
            phone: this.phone,
            email: this.email
        };
    }
    getSummaryForAppointment() {
        return {
            id: this.id,
            name: this.fullname(),
            phone: this.phone,
            email: this.email,
            hub_id: this.hub_id
        };
    }
    hasRestrictions(serviceIds: string[]) {
        if (!this.restrictions) {
            return false;
        }
        return this.restrictions.service_ids.some(x => serviceIds.includes(x));
    }
    hasGenderRestriction(groomerGender?: Gender) {
        if (!groomerGender)
            return false;

        return this.gender !== groomerGender;
    }
    getScheduleForDay(date: LocalDate, groomerExceptions: GroomerException[]) {
        const exception = groomerExceptions.find(x => x.groomer_id === this.id && x.belongsTo(date));
        if (exception) {
            if (!exception.is_available)
                return undefined;

            return {
                day: zeroBasedDaysOfWeek[date.getZeroBasedWeekDay()],
                from: formatTime24(exception.from, this.time_zone),
                to: formatTime24(exception.to, this.time_zone)
            } as GroomerScheduleData;
        }

        if (this.start_date && date.isLessThanDate(this.start_date, this.time_zone))
            return undefined;

        if (this.end_date && date.isGreaterThanDate(this.end_date, this.time_zone))
            return undefined;

        return this.schedule.find(x =>
            zeroBasedDaysOfWeek[date.getZeroBasedWeekDay()] === x.day
        );
    }
    isWorkingDay(day: string) {
        return this.schedule.some(x => x.day === day);
    }
    getWorkingHoursForDay(date: LocalDate, groomerExceptions: GroomerException[]) {
        const availability = this.getScheduleForDay(date, groomerExceptions);
        if (!availability) {
            return 0;
        }
        const groomerStart = date.toDateWithParsedTime(availability.from);
        const groomerEnd = date.toDateWithParsedTime(availability.to);
        const difference = DateTime.fromJSDate(groomerEnd).diff(DateTime.fromJSDate(groomerStart), "hours");
        return difference.hours;
    }
    isWithinAvailability(groomerExceptions: GroomerException[], startTime: Date, endTime: Date) {
        const localDate = LocalDate.forContextTimeZone(startTime, this.time_zone);
        const availability = this.getScheduleForDay(localDate, groomerExceptions);
        if (availability && availability.from && availability.to) {
            const groomerStart = localDate.toDateWithParsedTime(availability.from);
            const groomerEnd = localDate.toDateWithParsedTime(availability.to);
            return (
                startTime >= groomerStart &&
                endTime <= groomerEnd
            );
        }
        else {
            return false;
        }
    }
    getAssignedVan(vans: Van[], date: LocalDate, vanException?: VanException): AssignedVan {
        if (vanException) {
            const van = vans.find(x => x.id === vanException.van_id);
            return {
                van,
                exception_id: vanException.id,
            };
        }
        else {
            const van = vans.find(x => x.isAssignedToGroomer(this.id, date.getDayOfWeek()));
            return {
                van,
                exception_id: undefined,
            };
        }
    }
    static isDayOffForLocalDate(groomer: Groomer, date: LocalDate, groomerExceptions: GroomerException[]) {
        const exception = groomerExceptions.find(x => x.groomer_id === groomer.id && x.belongsTo(date));
        if (exception)
            return !exception.is_available;

        if (groomer.start_date && date.isLessThanDate(groomer.start_date, groomer.time_zone))
            return true;

        if (groomer.end_date && date.isGreaterThanDate(groomer.end_date, groomer.time_zone))
            return true;

        const workingDays = groomer.schedule.map(x => x.day);
        return zeroBasedDaysOfWeek
            .filter(weekDay => !workingDays.includes(weekDay))
            .some(weekDay => zeroBasedDaysOfWeek.findIndex(x => x === weekDay) === date.getZeroBasedWeekDay());
    }
    static isDayOff(groomer: Groomer, date: Date, groomerExceptions: GroomerException[]) {
        const localDate = LocalDate.forContextTimeZone(date, groomer.time_zone);
        return this.isDayOffForLocalDate(groomer, localDate, groomerExceptions);
    }
    static isTestGroomer(email: string) {
        const regExp = /\S+@test.\S+/;
        return regExp.test(email);
    }
    relativeDetailsPageUrl() {
        return `/groomers/${this.id}`;
    }
    genderPronoun() {
        if (!this.gender)
            return null;
        else {
            switch (this.gender) {
                case Gender.female:
                    return "She/Her";
                case Gender.male:
                    return "He/Him";
                case Gender.nonBinary:
                    return "They/Them";
                default:
                    shouldBeUnreachable(this.gender);
            }
        }
    }
    static getDefault() {
        return new Groomer("", {
            firstname: "",
            lastname: "",
            phone: "",
            email: "",
            hub_id: "",
            photo: "",
            role: "",
            uid: "",
            available_online: false,
            only_performs_bath: false,
            created_at: new Date(),
            schedule: [],
            status: GroomerStatus.active,
            time_zone: TimeZone.PacificTime,
            open_conversation: false
        });
    }
}

enum GroomerStatus {
    active = "active",
    inactive = "inactive",
    deleted = "deleted"
}

export { Groomer, GroomerStatus, Gender };
export type { GroomerData, GroomerScheduleData, GroomerRestrictionData, GroomerSummary, AssignedVan };