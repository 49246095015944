import { hubsCollection, getNonEmptyData } from "@marathon/client-side/database";
import { doc, getDoc, getDocs, updateDoc, DocumentSnapshot, UpdateData } from "firebase/firestore";
import { Hub, HubData } from "@marathon/common/entities/Hub";

const mapEntity = function (snapshot: DocumentSnapshot<HubData>) {
    return new Hub(snapshot.id, getNonEmptyData(snapshot));
};

export class HubRepository {
    static async getById(id: string) {
        const reference = doc(hubsCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
    static async search() {
        const snapshot = await getDocs(hubsCollection);
        return snapshot.docs.map(x => mapEntity(x));
    }
    static async update(id: string, data: UpdateData<HubData>) {
        const reference = doc(hubsCollection, id);
        await updateDoc(reference, data);
    }
}
