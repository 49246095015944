import { getDaysBetweenDates } from "@marathon/common/timeHelper";
import { getDayStartForTimeZone, TimeZone } from "@marathon/common/timeZoneHelper";
import { customerTypes, leadOutcomes, ONLINE_BOOKING_ORIGIN, soonestAppointmentFilters, SYSTEM_USER } from "@marathon/common/constants";
import { AppointmentData } from "@marathon/common/entities/Appointment";
import { AdsClickIdType } from "@marathon/common/entities/AdsActivity";
import { MobileServiceFeeConfiguration } from "@marathon/common/entities/Configuration";
import { MessageContactType } from "@marathon/common/entities/Message";
import LocalDate from "@marathon/common/LocalDate";
import { Hub } from "@marathon/common/entities/Hub";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";

interface CustomerAddressData {
    address1: string,
    address2: string,
    parking_notes?: string,
    state: string,
    city: string,
    country: string,
    zip?: string,
    area?: string,
    street_name?: string,
    place_id?: string,
    lat?: number,
    lng?: number,
    drive_time?: {
        duration_text: string,
        duration_value: number,
        hub_id: string
    }
}

interface CustomerAppointmentData {
    date: Date,
    groomer_id: string,
    groomer_name: string
}

interface CustomerData {
    firstname: string,
    lastname: string,
    phone: string,
    email: string,
    created_at: Date,
    air_call?: {
        contact_id: number,
        phone_id: number
    },
    address: CustomerAddressData,
    notes: string,
    manager_notes?: string,
    first_groomer?: string,
    first_appointment?: {
        date: Date,
        month: number,
        day: number
    },
    last_appointment?: CustomerAppointmentData,
    next_appointment?: CustomerAppointmentData,
    safety_accepted: boolean,
    skip_mobile_service_fee?: boolean,
    text_ok?: boolean,
    tos_pp_ok?: boolean,
    lead_info?: {
        type?: string,
        origin?: string,
        outcome?: string,
        previous_outcome?: string,
        assigned_user_id?: string,
        assigned_team_id?: string,
        new_lead?: boolean,
        open_conversation?: boolean,
        responded_by?: string
    },
    booked_by?: string,
    status: CustomerStatus,
    new_message?: boolean,
    last_message?: Date,
    last_message_content?: string,
    type: string,
    updated_at?: Date,
    updated_by?: string,
    closed_by?: string,
    outcome_updated_by?: string,
    twilio_hub_id?: string,
    starred?: boolean,
    in_standby?: boolean,
    standby_expiration?: {
        until: Date,
        task: string
    },
    vip?: boolean,
    vip_notes?: string,
    upcoming_count?: number,
    visits_count?: number,
    squareup_id?: string,
    imported?: boolean,
    square_created_at?: Date,
    soonest_appointment_filters?: string[],
    marketing_sms_opt_out?: boolean,
    marketing_email_opt_out?: boolean,
    postcard_opt_out?: boolean,
    blacklist?: boolean,
    blacklist_notes?: string,
    default_service_ids?: string[],
    last_activity_date?: Date,
    snoozed_conversation?: {
        task: string,
        until: Date
    },
    preferred_groomer_id?: string,
    days_between_appointments?: number,
    replied_from_us?: true,
    disallow_chatbot_interaction?: true,
    invitation_code?: string,
    last_postcard_sent_date?: Date,
    anonymous_uid?: string,
    live_agent_requested?: true,
    current_chatbot_activity_id?: string,
    klaviyo_profile_id?: string;
    ads_click_id?: CustomerAdsClickId,
    reminder_sms_opt_out?: boolean,
    has_extra_large_pets?: true,
    reactivate_chat_task?: string,
    open_online_booking_session?: {
        outcome: string
        started_at: Date
    }
}

interface Customer extends CustomerData {
    id: string
}

interface CustomerAdsClickId {
    value: string,
    type: AdsClickIdType,
    conversion_name: ConversionName
}

interface CustomerSummary {
    id: string,
    firstname: string,
    lastname: string,
    fullname: string,
    phone: string,
    email: string,
    preferredGroomerId?: string,
    street?: string,
    openConversation?: boolean
}

class Customer {
    constructor(id: string, data: CustomerData) {
        this.id = id;
        Object.assign(this, data);
    }
    fullname(): string {
        return this.firstname + " " + this.lastname;
    }
    hasValidAddress() {
        return (
            this.address.address1 &&
            this.address.city &&
            this.address.state &&
            this.address.country
        );
    }
    static formattedAddress(address: CustomerAddressData | SecondaryAddress): string {
        return address.address1 + ", " + address.city;
    }
    formattedAddress(): string {
        return Customer.formattedAddress(this.address);
    }
    toData(): CustomerData {
        const { id, ...data } = this;
        return data;
    }
    toSummary(): CustomerSummary {
        return {
            id: this.id,
            firstname: this.firstname,
            lastname: this.lastname,
            fullname: this.fullname(),
            phone: this.phone,
            email: this.email,
            street: this.address?.street_name
        };
    }
    isDeleted() {
        return this.status === CustomerStatus.deleted;
    }
    isRecurrent() {
        return this.type === customerTypes.recurrent;
    }
    isCustomer() {
        return this.type === customerTypes.customer;
    }
    isLead() {
        return this.type === customerTypes.lead;
    }
    placeId() {
        return this.address.place_id;
    }
    coordinates() {
        return Customer.getCoordinates(this.address);
    }
    timeZone(hubs: Hub[]) {
        const hub = hubs.find(x => x.id === this.address.drive_time?.hub_id);
        return hub?.time_zone ?? TimeZone.PacificTime;
    }
    static getCoordinates(address: { lat?: number, lng?: number }) {
        if (address && address.lat && address.lng) {
            return {
                lat: address.lat,
                lng: address.lng
            };
        }
        else {
            return undefined;
        }
    }
    getMainContact() {
        return {
            id: this.id,
            name: this.firstname,
            phone: this.phone,
            notes: "Main",
            type: MessageContactType.Main,
            created_at: new Date()
        };
    }
    isFirstTimer() {
        return (
            this.first_appointment &&
            this.first_appointment.date.getTime() > Date.now()
        );
    }
    hasAppointmentToday() {
        const todayFilter = soonestAppointmentFilters.today;
        return (
            this.soonest_appointment_filters?.includes(todayFilter) || false
        );
    }
    hasActivity(days: number, timeZone: TimeZone) {
        if (!this.last_activity_date)
            return false;

        return getDaysBetweenDates(getDayStartForTimeZone(this.last_activity_date, timeZone), getDayStartForTimeZone(new Date(), timeZone)) <= days;
    }
    isInboxInactive() {
        return (
            this.last_message_content === undefined &&
            this.last_activity_date === undefined
        );
    }
    isOutOfServiceArea() {
        const relevantOutcomes = [
            leadOutcomes.closed_lost_geography_limitation,
            leadOutcomes.closed_lost_geography_limitation_waitlist
        ];
        return (
            !!this.lead_info?.outcome &&
            relevantOutcomes.includes(this.lead_info.outcome)
        );
    }
    getSummaryForAppointment(selectedSecondaryAddress?: SecondaryAddress, isFromSignup?: boolean) {
        return {
            id: this.id,
            city: selectedSecondaryAddress ? selectedSecondaryAddress.city : this.address.city,
            area: selectedSecondaryAddress ? selectedSecondaryAddress.area : this.address.area,
            name: this.fullname(),
            phone: this.phone,
            email: this.email,
            notes: this.notes,
            is_from_signup: isFromSignup || false,
            drive_time_to_hub: selectedSecondaryAddress ? selectedSecondaryAddress.drive_time?.duration_value : this.address.drive_time?.duration_value,
            secondary_address_id: selectedSecondaryAddress ? selectedSecondaryAddress.id : undefined,
        };
    }
    hasEmailMarketingConsent() {
        return (
            this.text_ok && !this.marketing_email_opt_out && !this.blacklist
        );
    }
    assignedToTeam(teamId: string) {
        return this.lead_info?.assigned_team_id === teamId;
    }
    static isValidEmail(email: string) {
        const isValid =
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return isValid.test(email);
    }
    static relativeDetailsPageUrl(id: string) {
        return `/inbox/customers/${id}`;
    }
    static absoluteDetailsPageUrl(id: string, adminsAppUrl?: string) {
        const baseUrl = adminsAppUrl || "https://a.barkbus.com";
        return `${baseUrl}${Customer.relativeDetailsPageUrl(id)}`;
    }
    relativeDetailsPageUrl() {
        return Customer.relativeDetailsPageUrl(this.id);
    }
    absoluteDetailsPageUrl(adminsAppUrl?: string) {
        return Customer.absoluteDetailsPageUrl(this.id, adminsAppUrl);
    }
    isNameUnknown() {
        return (
            this.firstname === this.phone
        );
    }
    safetyFormUrl() {
        const firstnameWords = this.firstname.split(" ");
        const lastnameWords = this.lastname.split(" ");
        return `https://www.barkbus.com/safety?n=${firstnameWords[0]}+${lastnameWords[0]}&p=${this.phone}`;
    }
    finderValidAddress() {
        if (!this.address.drive_time || !this.address.place_id || !this.address.lat || !this.address.lng) {
            return null;
        }
        else {
            return {
                lat: this.address.lat,
                lng: this.address.lng,
                place_id: this.address.place_id,
                drive_time: this.address.drive_time
            };
        }
    }
    nonNullFinderValidAddress() {
        const validAddress = this.finderValidAddress();
        if (!validAddress)
            throw new Error(Customer.errorMessages.addressNotValidForBookingSuggestionsFinder);

        return validAddress;
    }
    getSquareLocationId(hubs: Hub[], sandboxLocationId?: string) {
        if (sandboxLocationId)
            return sandboxLocationId;
        else {
            const customerMainHub = hubs.find(x => x.id === this.address.drive_time?.hub_id);
            return customerMainHub?.square_location_id;
        }
    }
    getMainAddressHub(hubs: Hub[]) {
        return hubs.find(x => x.id === this.address.drive_time?.hub_id);
    }
    static isTestCustomer(email: string) {
        const regExp = /\S+@test.\S+/;
        return regExp.test(email);
    }
    static areChangesRelevantToKlaviyo(before: CustomerData | undefined, after: CustomerData) {
        if (!before)
            return true;

        return (
            before.email !== after.email ||
            before.phone !== after.phone ||
            before.firstname !== after.firstname ||
            before.lastname !== after.lastname ||
            before.type !== after.type ||
            //HACK: This should be commented out as the customer's pets information in Klaviyo is updated from the pets trigger
            //before.default_service_ids?.join(",") !== after.default_service_ids?.join(",") ||
            before.lead_info?.outcome !== after.lead_info?.outcome ||
            before.booked_by !== after.booked_by ||
            before.address.address1 !== after.address.address1 ||
            before.address.address2 !== after.address.address2 ||
            before.address.city !== after.address.city ||
            before.address.state !== after.address.state ||
            before.address.country !== after.address.country ||
            before.address.zip !== after.address.zip ||
            before.text_ok !== after.text_ok ||
            before.marketing_email_opt_out !== after.marketing_email_opt_out ||
            before.blacklist !== after.blacklist
        );
    }
    static getNewAppointmentHoldUpdateData(customer: Customer, appointment: AppointmentData) {
        return ({
            lead_info: { ...customer.lead_info, open_conversation: true },
            updated_by: `${SYSTEM_USER} (via ${appointment.booked_by})`
        });
    }
    static getDefaultAddress() {
        return ({
            address1: "",
            address2: "",
            parking_notes: "",
            state: "",
            city: "",
            country: "",
            zip: "",
            area: ""
        });
    }
    static getDefaultData(): CustomerData {
        return ({
            firstname: "",
            lastname: "",
            phone: "",
            email: "",
            address: this.getDefaultAddress(),
            notes: "",
            safety_accepted: false,
            text_ok: true,
            lead_info: {
                new_lead: false,
                open_conversation: true
            },
            created_at: new Date(),
            status: CustomerStatus.active,
            type: customerTypes.lead
        });
    }
    static wasUpdatedByCustomer(before: CustomerData, after: CustomerData) {
        const customerHasSentAMesage = before.last_message_content !== after.last_message_content && !!after.new_message;
        return customerHasSentAMesage || after.updated_by === ONLINE_BOOKING_ORIGIN;
    }
    static isOpeningConversation(before: CustomerData, after: CustomerData) {
        return (
            !before.lead_info?.open_conversation &&
            after.lead_info?.open_conversation &&
            Customer.wasUpdatedByCustomer(before, after)
        );
    }
    static isUnsnoozingConversation(before: CustomerData, after: CustomerData) {
        return (
            before.snoozed_conversation &&
            !after.snoozed_conversation &&
            after.lead_info?.open_conversation &&
            Customer.wasUpdatedByCustomer(before, after)
        );
    }
    static getChangesInformation(before: CustomerData, after: CustomerData) {
        const isAssigningUser = before.lead_info?.assigned_user_id !== after.lead_info?.assigned_user_id && !!after.lead_info?.assigned_user_id;
        const newAssignedUser = isAssigningUser ? after.lead_info?.assigned_user_id : null;

        const isUnassigningUser = before.lead_info?.assigned_user_id && !after.lead_info?.assigned_user_id;
        const unassignedUserId = isUnassigningUser ? before.lead_info?.assigned_user_id : null;

        const isAssigningTeam = before.lead_info?.assigned_team_id !== after.lead_info?.assigned_team_id && after.lead_info?.assigned_team_id;
        const newAssignedTeam = isAssigningTeam ? after.lead_info?.assigned_team_id : null;

        const isUnassigningTeam = before.lead_info?.assigned_team_id && !after.lead_info?.assigned_team_id;
        const unnasignedTeamId = isUnassigningTeam ? before.lead_info?.assigned_team_id : null;

        const isSnoozingConversation = before.snoozed_conversation?.until?.getTime() !== after.snoozed_conversation?.until?.getTime() && !!after.snoozed_conversation?.until;
        const isUnsnoozingConversation = before.snoozed_conversation && !after.snoozed_conversation && after.lead_info?.open_conversation;

        const isOpeningConversation = !before.lead_info?.open_conversation && after.lead_info?.open_conversation && !isUnsnoozingConversation;
        const isClosingConversation = before.lead_info?.open_conversation && !after.lead_info?.open_conversation && !isSnoozingConversation;

        return {
            newAssignedUser,
            newAssignedTeam,
            unnasignedTeamId,
            isSnoozingConversation,
            isUnsnoozingConversation,
            isOpeningConversation,
            isClosingConversation,
            unassignedUserId
        };
    }
    get qualifiesFor() {
        return {
            mobileServiceFeeDiscount: (mobileServiceFee: MobileServiceFeeConfiguration) => {
                if (this.skip_mobile_service_fee)
                    return false;
                else if (!mobileServiceFee.old_price)
                    return true;
                else {
                    const { old_price } = mobileServiceFee;
                    return (
                        LocalDate.forContextTimeZone(old_price.created_at_to, TimeZone.PacificTime).isLessThanDate(this.created_at) ||
                        LocalDate.forContextTimeZone(old_price.valid_to, TimeZone.PacificTime).isLessThanDate(new Date())
                    );
                }
            },
            firstOccurrenceDiscount: (hubs: Hub[], invitationCode?: string) => {
                return !this.isRecurrent() && !this.qualifiesFor.firstTimeCustomerDiscount(hubs, invitationCode);
            },
            firstTimeCustomerDiscount: (hubs: Hub[], invitationCode?: string) => {
                const customerHub = hubs.find(x => x.id === this.address.drive_time?.hub_id);
                const discount = customerHub?.first_time_customer_discount;
                return this.type === customerTypes.lead && discount?.is_enabled && !this.qualifiesFor.referralDiscount(invitationCode);
            },
            referralDiscount: (invitationCode?: string) => {
                return !!invitationCode;
            }
        };
    }
    get supports() {
        return {
            chatbot: () => {
                if (this.disallow_chatbot_interaction || this.blacklist) {
                    return false;
                }
                else if (this.isLead()) {
                    return true;
                }
                else if (this.isCustomer()) {
                    return (
                        !!this.address.drive_time?.hub_id &&
                        !!this.default_service_ids?.length
                    );
                }
                else {
                    return false;
                }
            },
            voiceChatbot: () => {
                return (this.isCustomer() &&
                    !this.disallow_chatbot_interaction &&
                    !this.blacklist &&
                    this.visits_count &&
                    this.visits_count >= 1 &&
                    !!this.address.drive_time?.hub_id &&
                    this.address.lat &&
                    this.address.lng &&
                    !!this.default_service_ids?.length);
            },
            bookingSuggestionsFinder: () => {
                const hasMissingInformation =
                    !this.default_service_ids?.length ||
                    !this.address.place_id ||
                    !this.address.drive_time ||
                    !this.address.lat ||
                    !this.address.lng;

                return !hasMissingInformation;
            }
        };
    }
    static get errorMessages() {
        return {
            bookingSuggestionsFinderNotSupported: "Customer address is not valid (must be geocoded and within service area) or missing pets information",
            addressNotValidForBookingSuggestionsFinder: "Customer address is not valid (must be geocoded and within service area)"
        };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static fromApi(serialized: any) {
        const { id, ...data } = serialized;
        return new Customer(id, data);
    }
}

enum CustomerStatus {
    active = "active",
    deleted = "deleted"
}

enum ConversionName {
    BookedOnline = "booked_online",
    BookedCall = "booked_call",
    BookedSms = "booked_SMS"
}

export { Customer, CustomerStatus, ConversionName };
export type { CustomerData, CustomerSummary, CustomerAddressData, CustomerAppointmentData, CustomerAdsClickId };