import { breedsCollection, getNonEmptyData } from "@marathon/client-side/database";
import { doc, getDoc, getDocs, query, orderBy, DocumentSnapshot } from "firebase/firestore";
import { Breed, BreedData } from "@marathon/common/entities/Breed";

const mapEntity = function (snapshot: DocumentSnapshot<BreedData>) {
    return new Breed(snapshot.id, getNonEmptyData(snapshot));
};

export class BreedRepository {
    static async getById(id: string) {
        const reference = doc(breedsCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
    static async search() {
        const snapshot = await getDocs(
            query(breedsCollection, orderBy("name", "asc"))
        );
        return snapshot.docs.map(x => mapEntity(x));
    }
}
