import { PricingPreset, PricingPresetData } from "@marathon/common/entities/PricingPreset";
import { pricingPresetsCollection, getNonEmptyData } from "@marathon/client-side/database";
import { doc, getDoc, getDocs, DocumentSnapshot } from "firebase/firestore";

const mapEntity = function (snapshot: DocumentSnapshot<PricingPresetData>) {
    return new PricingPreset(snapshot.id, getNonEmptyData(snapshot));
};

export class PricingPresetRepository {
    static async getById(id: string) {
        const reference = doc(pricingPresetsCollection, id);
        const snapshot = await getDoc(reference);
        if (!snapshot.exists()) {
            return null;
        }
        return mapEntity(snapshot);
    }
    static async search() {
        const snapshot = await getDocs(pricingPresetsCollection);
        return snapshot.docs.map(x => mapEntity(x));
    }
}