import { discountsCollection, getNonEmptyData } from "@marathon/client-side/database";
import { DocumentSnapshot, getDocs } from "firebase/firestore";
import { Discount, DiscountData } from "@marathon/common/entities/Discount";

const mapEntity = function (snapshot: DocumentSnapshot<DiscountData>) {
    return new Discount(snapshot.id, getNonEmptyData(snapshot));
};

export class DiscountRepository {
    static async search() {
        const snapshot = await getDocs(discountsCollection);
        return snapshot.docs.map(x => mapEntity(x));
    }
}