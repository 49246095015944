import { addDoc, DocumentSnapshot, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { ReferralActivity, ReferralActivityData, ReferralEventType } from "@marathon/common/entities/ReferralActivity";
import LocalDate from "@marathon/common/LocalDate";
import { referralActivitiesCollection, getNonEmptyData } from "@marathon/client-side/database";
import { Customer } from "@marathon/common/entities/Customer";

const mapEntity = function (snapshot: DocumentSnapshot<ReferralActivityData>) {
    return new ReferralActivity(snapshot.id, getNonEmptyData(snapshot));
};

export class ReferralActivityRepository {
    static async searchForReport(fromDate: LocalDate, toDate: LocalDate, pageSize?: number, afterDocument?: Document) {
        let subQuery = query(referralActivitiesCollection,
            where("created_at", ">=", fromDate.toDayStart()),
            where("created_at", "<=", toDate.toDayEnd()),
            orderBy("created_at", "desc"));

        if (pageSize)
            subQuery = query(subQuery, limit(pageSize));

        if (afterDocument)
            subQuery = query(subQuery, startAfter(afterDocument));

        const snapshot = await getDocs(subQuery);
        return {
            activities: snapshot.docs.map(x => mapEntity(x)),
            lastDocument: snapshot.docs[snapshot.docs.length - 1]
        };
    }
    static async create(customer: Customer, type: ReferralEventType, sharedUrl: string) {
        const data = {
            customer_id: customer.id,
            customer_name: customer.fullname(),
            created_at: new Date(),
            type,
            sharedUrl
        };
        const newDocument = await addDoc(referralActivitiesCollection, data);
        return new ReferralActivity(newDocument.id, data);
    }
}